import { useState, useEffect, useRef } from "react";

export function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver, { passive: true });
        node.addEventListener("mouseout", handleMouseOut, { passive: true });
        node.addEventListener("touchstart", handleMouseOver, { passive: true });
        node.addEventListener("touchend", handleMouseOut, { passive: true });
        return () => {
          node.removeEventListener("mouseover", handleMouseOver, { passive: true });
          node.removeEventListener("mouseout", handleMouseOut, { passive: true });
          node.removeEventListener("touchstart", handleMouseOver, { passive: true });
          node.removeEventListener("touchend", handleMouseOut, { passive: true });
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value, setValue];
}
