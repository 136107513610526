import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUiStore } from "../hooks";
import { useTranslation } from "react-i18next";

const get = async (url) => {
  const res = await fetch(url);
  const content = await res.json();
  if (res.ok) return content;
  else
    throw {
      status: res.status,
      ...content,
    };
};

async function post(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.status === 204) return null;
  const content = await response.json();
  if (response.ok) {
    return content;
  } else
    throw {
      status: response.status,
      ...content,
    };
}

async function patch(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.status === 204) return null;
  const content = await response.json();
  if (response.ok) {
    return content;
  } else
    throw {
      status: response.status,
      ...content,
    };
}

async function destroy(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.status === 204) return null;
  const content = await response.json();
  if (response.ok) {
    return content;
  } else
    throw {
      status: response.status,
      ...content,
    };
}

export const useConfirmAccount = (opts = {}) => {
  const { locale } = useParams();
  return useMutation({
    mutationFn: (token) =>
      get(`/${locale}/users/confirmation.json?confirmation_token=${token}`),
    ...opts,
  });
};

export const useDestroySession = (opts = {}) => {
  const toast = useToast();
  const ui = useUiStore();
  const client = useQueryClient();
  const navigate = useNavigate();
  const { locale } = useParams();
  return useMutation({
    mutationFn: () => destroy(`/${locale}/users/sign_out.json`),
    onSuccess: (res, args) => {
      navigate(`/${locale}`);
      ui.setSignedIn(false);
      client.resetQueries();
      toast({
        title: "Abmeldung erfolgreich",
        description: "Bis bald",
        status: "success",
      });
    },
    onError: (res, args) => {
      toast({
        title: "Abmeldung nicht erfolgreich",
        description: res.error,
        status: "error",
      });
    },
    ...opts,
  });
};

export const useResetPassword = (opts = {}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { locale } = useParams();
  const ui = useUiStore();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (args) =>
      patch(`/${locale}/users/password.json`, { user: args }),
    onSuccess: (res, args) => {
      toast({
        title: t("reset-password-success"),
        description: t("reset-password-success-description"),
        status: "success",
      });
      ui.setSignedIn(true);
      navigate(`/${locale}/user`);
    },
    ...opts,
  });
};

export const useResendConfirmation = (opts = {}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (args) =>
      post(`/${locale}/users/confirmation.json`, { user: args }),
    onSuccess: (res, args) => {
      toast({
        title: t("resend-confirmation-success"),
        description: t("resend-confirmation-success-description"),
        status: "success",
      });
      navigate(`/${locale}`);
    },
    ...opts,
  });
};

export const useRequestPasswordLink = (opts = {}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (args) =>
      post(`/${locale}/users/password.json`, { user: args }),
    onSuccess: (res, args) => {
      toast({
        title: t("request-password-link-success"),
        description: t("request-password-link-success-description"),
        status: "success",
      });
      navigate(`/${locale}`);
    },
    ...opts,
  });
};

export const useCreateSession = (opts = {}) => {
  let redirect;
  const ui = useUiStore();
  const client = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const { locale } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  if (searchParams.has("redirect"))
    redirect = decodeURIComponent(searchParams.get("redirect"));
  else redirect = `/${locale}/user`;
  return useMutation({
    mutationFn: (args) => post(`/${locale}/users/sign_in.json`, { user: args }),
    onSuccess: (res, args) => {
      ui.setSignedIn(true);
      client.invalidateQueries(["signed_in"]);
      toast({
        title: t("sign-in-success"),
        description: t("sign-in-success-description"),
        status: "success",
      });
      navigate(redirect);
    },
    ...opts,
  });
};

export const useUpdateUser = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (args) =>
      patch(`/${locale}/users.json?`, {
        user: args,
      }),
    ...opts,
    onSuccess: (res, args) => {
      toast({ title: t("settings-saved"), status: "success" });
    },
  });
};

export const useUpdateProfile = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (args) =>
      patch(`/storefront/profile.json?` + new URLSearchParams({ locale }), {
        profile: args,
      }),
    ...opts,
    onSuccess: (res, args) => {
      toast({ title: t("settings-saved"), status: "success" });
    },
  });
};

export const useUpdateAddress = (opts = {}) => {
  const { locale } = useParams();
  const client = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: ({ id, ...args }) =>
      patch(
        `/storefront/addresses/${id}.json?` + new URLSearchParams({ locale }),
        { address: args },
      ),
    ...opts,
    onSuccess: (res, args) => {
      toast({ title: t("address-saved"), status: "success" });
      client.invalidateQueries(["addresses", locale]);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res, args);
    },
  });
};

export const useCreateWaitlisting = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  const client = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (args) =>
      post(
        `/storefront/wait_list_items.json?` + new URLSearchParams({ locale }),
        { wait_list_item: args },
      ),
    onSuccess: (res, args) => {
      client.setQueryData(["products", res.slug], res);
      toast({ title: t("waitlisting-created"), status: "success" });
    },
    ...opts,
  });
};

export const useCreateReservation = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  const client = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (args) =>
      post(`/storefront/reservations.json?` + new URLSearchParams({ locale }), {
        reservation: args,
      }),
    onSuccess: (res, args) => {
      client.setQueryData(["products", res.slug], res);
      toast({ title: t("reservation-created"), status: "success" });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
    ...opts,
  });
};

export const useCreateAddress = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args) =>
      post(`/storefront/addresses.json?` + new URLSearchParams({ locale }), {
        address: args,
      }),
    ...opts,
    onSuccess: (res, args) => {
      toast({ title: "Addresse erstellt", status: "success" });
      client.invalidateQueries(["addresses", locale]);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useUpdateOrder = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  return useMutation({
    mutationFn: ({ id, paymentId }) =>
      patch(
        `/storefront/orders/${id}.json?` +
          new URLSearchParams({ locale, payment_id: paymentId }),
      ),
    onSuccess: (res, args) => {
      toast({ title: "Zahlung bestätigt", status: "success" });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
    ...opts,
  });
};

export const useCreateOrder = (opts = {}) => {
  const { locale } = useParams();
  const toast = useToast();
  return useMutation({
    mutationFn: (args) =>
      post(`/storefront/orders.json?` + new URLSearchParams({ locale }), {
        order: args,
      }),
    onSuccess: (res, args) => {
      toast({ title: "Bestellung erstellt", status: "success" });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
    ...opts,
  });
};

export const useCreateRegistration = (opts = {}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (args) => post(`/${locale}/users.json`, { user: args }),
    onSuccess: (res, args) => {
      toast({
        title: "Wir haben deine Registrierung erhalten",
        description: "Herzlich Willkommen bei Figuya",
        status: "success",
      });
      navigate("../sign-up-submitted");
    },
    ...opts,
  });
};

export const useUpdateWatchlist = (opts = {}) => {
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: ({ id, ...args }) =>
      patch(
        `/storefront/watch_lists/${id}.json?` + new URLSearchParams({ locale }),
        { watch_list: args },
      ),
    onSuccess: (res, args) => {
      client.setQueryData(["watch_lists", res.id], res);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
    ...opts,
  });
};

export const useCreateWatchlist = (opts = {}) => {
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (args) =>
      post(`/storefront/watch_lists.json?` + new URLSearchParams({ locale }), {
        watch_list: args,
      }),
    ...opts,
    onSuccess: (res, args) => {
      client.setQueryData(["watch_lists", res.id], res);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useUpdateWatchlistEntry = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const { id } = useParams();
  const { locale } = useParams();
  return useMutation({
    mutationFn: ({ id, ...args }) =>
      patch(
        `/storefront/watch_list_entries/${id}.json?` +
          new URLSearchParams({ locale }),
        { watch_list_entry: args },
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.invalidateQueries([
        "watch_lists",
        {
          id,
          view: "show",
          page: 1,
          term: "",
          direction: "desc",
          order: "created_at",
        },
      ]);
      toast({ title: "Merkliste aktualisiert", status: "success" });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res, args);
    },
  });
};

export const useUpdateDownPayment = (opts = {}) => {
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: ({ id, ...body }) =>
      patch(
        `/storefront/down_payments/${id}.json?` +
          new URLSearchParams({ locale }),
        { down_payment: body },
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.invalidateQueries(["down_payments"]);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useCreateWatchlistEntry = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const { locale, id } = useParams();
  return useMutation({
    mutationFn: (args) =>
      post(
        `/storefront/watch_list_entries.json?` +
          new URLSearchParams({ locale }),
        { watch_list_entry: args },
      ),
    onSuccess: (res, args) => {
      toast({
        title: "Merkliste aktualisiert",
        status: "success",
      });
      client.invalidateQueries([
        "watch_lists",
        { product_id: args.product_id },
      ]);
      client.setQueryData(
        [
          "watch_lists",
          {
            id,
            view: "show",
            page: 1,
            term: "",
            direction: "desc",
            order: "created_at",
          },
        ],
        res,
      );
    },
    ...opts,
  });
};

export const useDestroyTaxonSubscription = (opts = {}) => {
  const toast = useToast();
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (id) =>
      destroy(
        `/storefront/taxon_subscriptions/${id}.json?` +
          new URLSearchParams({ locale }),
      ),
    onSuccess: (res, args) => {
      client.setQueryData(
        ["taxons", { slug: res.slug, view: "subscription" }],
        res,
      );
      client.invalidateQueries(["taxon_subscriptions"]);
      toast({ title: "Abonnement entfernt", status: "success" });
    },
    ...opts,
  });
};

export const useDestroyWaitlisting = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (id) =>
      destroy(
        `/storefront/wait_list_items/${id}.json?` +
          new URLSearchParams({ locale }),
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.invalidateQueries(["wait_list_items"]);
      toast({
        title: t("wait-list-item"),
        description: t("canceled"),
        status: "success",
      });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useDestroyReservation = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const { locale } = useParams();
  return useMutation({
    mutationFn: ({ id, ...args }) =>
      destroy(
        `/storefront/reservations/${id}.json?` +
          new URLSearchParams({ locale }),
        { reservation_cancellation: args },
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.invalidateQueries(["reservations"]);
      toast({
        title: t("reservation"),
        description: t("canceled"),
        status: "success",
      });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useDestroyWatchlistEntry = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const { locale, id } = useParams();
  return useMutation({
    mutationFn: (id) =>
      destroy(
        `/storefront/watch_list_entries/${id}.json?` +
          new URLSearchParams({ locale }),
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.setQueryData(
        [
          "watch_lists",
          {
            id,
            view: "show",
            page: 1,
            term: "",
            direction: "desc",
            order: "created_at",
          },
        ],
        (old) => ({
          ...old,
          watch_list_entries: old.watch_list_entries.filter(
            (e) => args !== e.id,
          ),
        }),
      );
      toast({ title: "Merkliste aktualisiert", status: "success" });
      if (typeof opts.onSuccess === "function") opts.onSuccess(res, args);
    },
  });
};

export const useDestroyWatchlist = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (id) =>
      destroy(
        `/storefront/watch_lists/${id}.json?` + new URLSearchParams({ locale }),
      ),
    ...opts,
    onSuccess: (res, args) => {
      if (typeof opts.onSuccess === "function") opts.onSuccess(res, args);
      client.invalidateQueries(["watch_lists"]);
      toast({ title: "Merkliste gelöscht", status: "success" });
    },
  });
};

export const useCreateTaxonSubscription = (opts = {}) => {
  const toast = useToast();
  const client = useQueryClient();
  const { locale } = useParams();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (args) =>
      post(
        `/storefront/taxon_subscriptions.json?` +
          new URLSearchParams({ locale }),
        { taxon_subscription: args },
      ),
    onSuccess: (res, args) => {
      toast({
        title: t(`subscription-success`, {
          taxonomy: res.taxonomy.title,
          taxon: res.title,
        }),
        status: "success",
      });
      client.setQueryData(
        ["taxons", { slug: res.slug, view: "subscription" }],
        res,
      );
    },
    ...opts,
  });
};

export const useUpdateCartLine = (opts = {}) => {
  const client = useQueryClient();
  const toast = useToast();
  const ui = useUiStore();
  const { t } = useTranslation();
  const { locale } = useParams();
  return useMutation({
    mutationFn: ({ action, ...args }) =>
      patch(`/storefront/cart.json?` + new URLSearchParams({ locale }), args),
    ...opts,
    onError: (res) => {
      toast({ status: "error", title: t("cart"), description: res.message });
    },
    onSuccess: (res, args) => {
      toast({
        title: "Warenkorb aktualisiert",
        status: "success",
      });
      client.setQueryData(["cart", locale], res);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useUpdatePromotionCode = (opts = {}) => {
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (args) =>
      post(
        `/storefront/promotion_codes.json?` + new URLSearchParams({ locale }),
        { checkout: args },
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.setQueryData(["cart", locale], res);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useUpdateCheckout = (opts = {}) => {
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (args) =>
      patch(`/storefront/checkout.json?` + new URLSearchParams({ locale }), {
        checkout: args,
      }),
    ...opts,
    onSuccess: (res, args) => {
      client.setQueryData(["checkout"], res);
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};

export const useUpdatePaypalPayment = (opts = {}) => {
  const client = useQueryClient();
  const { locale } = useParams();
  return useMutation({
    mutationFn: (id) =>
      patch(
        `/storefront/paypal_payments/${id}.json?` +
          new URLSearchParams({ locale }),
      ),
    ...opts,
    onSuccess: (res, args) => {
      client.setQueryData(["paypal_payments", args], (old) => ({
        ...old,
        checkout: res,
      }));
      if (typeof opts.onSuccess === "function") opts.onSuccess(res);
    },
  });
};
