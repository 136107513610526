import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl } from "@fortawesome/pro-regular-svg-icons/faListUl";
import { Button } from "@chakra-ui/react";
import { useUiStore } from "../hooks";
import { useTranslation } from "react-i18next";

export const AddToWatchlistButton = ({ product, size = "sm" }) => {
  const ui = useUiStore();
  const { t } = useTranslation();
  return (
    <Button
      variant="outline"
      colorScheme="gray"
      onClick={() => ui.toggleWatchlistModal(product)}
      size={size}
      leftIcon={<FontAwesomeIcon icon={faListUl} />}
    >
      {t("add-to-watchlist")}
    </Button>
  );
};
