import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Grid, Box, Heading, Stack, Image, Flex } from "@chakra-ui/react";
import ProductShippingNotice from "./shipping-notice";
import ProductAttributeTable from "./attributes";
import { Headline, Description, LoadingIndicator } from "../elements";
import { ProductImages } from "./ProductImages";
import { useFetchProduct, useFetchTaxon } from "../api";
import { AddToCartButton } from "./AddToCartButton";
import { ReserveButton } from "./ReserveButton";
import { WaitlistButton } from "./WaitlistButton";
import { AddToWatchlistButton } from "./AddToWatchlistButton";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { flatMap } from "lodash-es";
import { ProductCard } from "./ProductCard"
import * as CC from "vanilla-cookieconsent";

export const productBreadcrumbs = (locale) =>
({
  de: {
    text: "Alle Produkte",
    href: "/de/produkte",
  },
  en: {
    text: "All Products",
    href: "/en/products",
  },
}[locale]);

const ldJsonAvailability = (availability) => {
  switch (availability) {
    case "stocked":
      return "https://schema.org/InStock";
    case "preorder":
      return "https://schema.org/PreOrder";
    default:
      return "https://schema.org/SoldOut";
  }
};

export const Product = () => {
  const { locale, slug, taxonId } = useParams();
  const { t } = useTranslation();
  const [_isLoaded, setLoaded] = React.useState(false);
  useEffect(() => {
    setLoaded(false);
  }, [slug]);
  const taxonQuery = useFetchTaxon(
    taxonId,
    { view: "show" },
    { refetchOnMount: false, enabled: taxonId !== undefined }
  );
  const productQuery = useFetchProduct(slug);
  React.useEffect(() => {
    if (productQuery.isSuccess) {
      if (CC.acceptedCategory('ads') && typeof window.fbq === 'function') {
        window.fbq("track", "ViewContent", { content_ids: [id] });
      }
    }
  }, [productQuery.isSuccess]);
  const location = useLocation();
  if (productQuery.isLoading) return <LoadingIndicator />;

  const {
    id,
    href,
    profile_picture,
    description,
    stock_state,
    gross_selling_price,
    title,
    meta_title,
    meta_description,
    tagline,
    alternates = [],
    taxonomies = [],
    images,
    nsfw,
  } = productQuery.data;
  const isNsfw =
    flatMap(taxonomies, (taxonomy) =>
      taxonomy.taxons.map((taxon) => taxon.id)
    ).includes(71) || nsfw;
  return (
    <>
      <Outlet />
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        {alternates.map(({ href, lang }) => (
          <link
            key={lang}
            rel="alternate"
            href={href + location.search}
            hrefLang={lang}
          />
        ))}
        {isNsfw ? (
          <meta name="age‐value‐meta‐label" content="hentai" />
        ) : (
          <meta name="age‐value‐meta‐label" content="figuya" />
        )}
        {isNsfw ? (
          <meta name="age‐meta‐label" content="age=18" />
        ) : (
          <meta name="age‐meta‐label" content="age=12" />
        )}
        <script type="application/ld+json">
          {`
{
"@context": "https://schema.org/",
"@type": "Product",
"name": "${title}",
"image": ["https://figuya.com${profile_picture.profile.url}"],
"sku": "${id}",
"offers": {
"@type": "Offer",
"url": "https://figuya.com${href}",
"priceCurrency": "EUR",
"price": "${gross_selling_price}",
"itemCondition": "https://schema.org/NewCondition",
"availability": "${ldJsonAvailability(stock_state)}",
"seller": {
"@type": "Organization",
"name": "Figuya GmbH"
}
}
}
          `}
        </script>
      </Helmet>
      <Stack spacing={8}>
        <Flex gap={[2, 4]} direction="column" overflow="clip" rounded="md">
          <Flex alignItems="center" direction="row" gap="2" bg="orange.600" color="orange.50" px={[2, 4]} py={[4, 8]}>
            <Flex flex="1" direction="column" gap="2">
              {taxonId && taxonQuery.isSuccess ? (
                <Breadcrumb display="flex" sx={{
                  webkitOverflowScrolling: "touch",
                  '::-webkit-scrollbar': { display: 'none' }
                }} whiteSpace="nowrap" overflowY="hidden" width="100%" flexDirection="row" overflowX="auto"
                  separator={<FontAwesomeIcon icon={faChevronRight} />}>
                  <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={`/${locale}`}>
                      Home
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={taxonQuery.data.taxonomy.href}>
                      {taxonQuery.data.taxonomy.title}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={taxonQuery.data.href}>
                      {taxonQuery.data.title}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              ) : (
                <Breadcrumb display="flex" sx={{
                  webkitOverflowScrolling: "touch",
                  '::-webkit-scrollbar': { display: 'none' }
                }} whiteSpace="nowrap" overflowY="hidden" width="100%" flexDirection="row" overflowX="auto"

                  separator={<FontAwesomeIcon icon={faChevronRight} />}>
                  <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={`/${locale}`}>
                      Home
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={`/${locale}/products`}>
                      {t("products")}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              )}
              <Heading size={["md", "lg"]} as="h1">{title}</Heading>
              <Box fontWeight="semibold">{tagline}</Box>
            </Flex>
            <Box
              borderWidth="2px"
              rounded="md"
              overflow="clip"
              borderColor="orange.50">
              <Image
                htmlWidth="96"
                htmlHeight="96"
                alt={title}
                src={profile_picture.small_profile_webp.url}
              />
            </Box>

          </Flex>
          <Flex wrap="wrap" gap={4} as="section">
            <Flex
              alignItems="stretch"
              minWidth="300px"
              flex="1"
              direction="column"
            >
              <Flex position="sticky" gap="2" direction="column" top="64px">
                <Image
                  onLoad={() => setLoaded(true)}
                  onClick={() => {
                    document.getElementById("images").scrollIntoView({ behavior: "smooth" })
                  }}
                  htmlWidth="285"
                  htmlHeight="285"
                  width="100%"
                  alt={title}
                  src={images.length > 0 ? images[0].file.url : profile_picture.large_profile.url}
                />
                <AddToCartButton product={productQuery.data} size="md" />
                <ReserveButton product={productQuery.data} size="md" />
                <WaitlistButton product={productQuery.data} size="md" />
                <AddToWatchlistButton product={productQuery.data} size="md" />
              </Flex>
            </Flex>
            <Flex gap={4} minWidth="250px" direction="column" flex="2">
              <ProductAttributeTable product={productQuery.data} />
              <ProductShippingNotice stock_state={stock_state} />
              <ProductImages rounded="md" images={images || []} />
              <Description>{description}</Description>
            </Flex>
          </Flex>
        </Flex>
        <Box mt="16">
          <Heading size="lg" as="div">
            {t("similar-items")}
          </Heading>
          <Box mb="4" fontFamily="heading" letterSpacing="wider" fontSize="lg">
            {t("you-might-like")}
          </Box>
          <Grid
            rowGap={[8, 16]}
            columnGap={[4, 8]}
            overflow="clip"
            templateColumns={[
              "repeat(auto-fill,minmax(160px, 1fr))",
              "repeat(auto-fill,minmax(180px, 1fr))",
              "repeat(auto-fill,minmax(260px, 1fr))",
            ]}
          >
            {productQuery.data.recommendations.map((product) => (
              <ProductCard
                href={product.href}
                product={product}
                key={product.id}
              />
            ))}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
