import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarLinesPen } from "@fortawesome/pro-regular-svg-icons/faCalendarLinesPen";
import { Button } from "@chakra-ui/react";
import { useUiStore } from "../hooks";
import { useTranslation } from "react-i18next";

export const WaitlistButton = ({ product, size = "sm" }) => {
  const ui = useUiStore();
  const { t } = useTranslation();
  return (
    <Button
      variant="outline"
      isDisabled={!product.waitlistable}
      onClick={() => ui.toggleWaitlistModal(product)}
      size={size}
      colorScheme="blue"
      leftIcon={<FontAwesomeIcon icon={faCalendarLinesPen} />}
    >
      {t("join-waitlist")}
    </Button>
  );
};
