import React from "react";
import { Link, useParams } from "react-router-dom";
import { Heading, Image, Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { useSearchUi, useUiStore } from "../hooks";
import { useFetchSearchItems, useFetchTaxonSubscriptions } from "../api/queries";
import { ProductPrice, ProductState } from "../products";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons/faQuestion";
import { useTranslation } from "react-i18next";
import { MenuContainer } from "./MenuContainer";
import { SearchTaxonomy } from "./SearchTaxonomy";
import { faFolders } from "@fortawesome/pro-regular-svg-icons/faFolders";
import { faFolderHeart } from "@fortawesome/pro-regular-svg-icons/faFolderHeart";
import { TaxonomyIcon } from "../taxonomies/TaxonomyIcon";

const MenuItem = ({
  icon = <FontAwesomeIcon icon={faSearch} />,
  children,
  onClick,
}) => {
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  return (
    <Flex
      cursor="pointer"
      onClick={onClick}
      _hover={{ bg: hoverBg }}
      gap={2}
      p={1}
      alignItems="center"
    >
      <Flex
        rounded="sm"
        flex="0 0 64px"
        bg="orange.500"
        color="white"
        height="64px"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Flex>
      <Heading noOfLines={1} as="div" size="sm">
        {children}
      </Heading>
    </Flex>
  );
};

const ResultItem = ({ item }) => {
  const ui = useSearchUi();
  if (item.result_type === "product")
    return <ProductItem product={item} ui={ui} />;
  if (item.result_type === "taxon") return <TaxonItem taxon={item} ui={ui} />;
  if (item.result_type === "question")
    return <QuestionItem question={item} ui={ui} />;
};
const QuestionItem = ({ question, ui }) => {
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  const { locale } = useParams();

  return (
    <Flex
      onClick={ui.closeSearch}
      as={Link}
      to={`/${locale}/help-desk/${question.id}`}
      _hover={{ bg: hoverBg }}
      gap={2}
      p={1}
      alignItems="center"
    >
      <Flex
        rounded="sm"
        flex="0 0 64px"
        bg="orange.500"
        color="white"
        height="64px"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faQuestion} />
      </Flex>
      <Heading noOfLines={1} as="div" size="sm">
        {question.title}
      </Heading>
      <Box flex="1" minWidth="10px" borderBottomWidth="2px" />
      <Box>{question.topic}</Box>
    </Flex>
  );
};

const ProductItem = ({ product, ui }) => {
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");

  return (
    <Flex
      onClick={ui.closeSearch}
      as={Link}
      to={product.href}
      _hover={{ bg: hoverBg }}
      gap={2}
      p={1}
      alignItems="center"
    >
      <Box flex="0 0 64px">
        <Image
          htmlWidth="64"
          htmlHeight="64"
          rounded="sm"
          src={product.profile_picture.thumb.url}
        />
      </Box>
      <Box>
        <ProductState product={product} />
        <Heading noOfLines={1} as="div" size="sm">
          {product.title}
        </Heading>
      </Box>
      <Box flex="1" minWidth="10px" borderBottomWidth="2px" />
      <ProductPrice taxHint={false} product={product} />
    </Flex>
  );
};

export const TaxonItem = ({ taxon, ui }) => {
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  return (
    <Flex
      onClick={() => {
        ui.setQuery({ term: "", taxon_ids: taxon?.id ? [taxon.id] : [] });
        ui.closeSearch();
      }}
      as={Link}
      to={taxon.href}
      _hover={{ bg: hoverBg }}
      p={1}
      direction="row"
      gap={2}
      alignItems="center"
    >
      <Flex
        rounded="sm"
        flex="0 0 64px"
        bg="orange.500"
        color="white"
        height="64px"
        alignItems="center"
        justifyContent="center"
      >
        <TaxonomyIcon size="2x" id={taxon.taxonomy.id} />
      </Flex>
      <Box>
        <Heading noOfLines={1} as="div" size="sm">
          {taxon.title}
        </Heading>
        {taxon?.original_title && (
          <Box fontSize="sm">{taxon?.original_title}</Box>
        )}
        <Box fontSize="sm">{taxon?.taxonomy?.title}</Box>
      </Box>
    </Flex>
  );
};

function lockScroll() {
  // Store the current scroll position
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  document.documentElement.style.setProperty("--scroll-top", `${scrollTop}px`);

  // Lock the scroll
  document.documentElement.classList.add("no-scroll");
}

function unlockScroll() {
  // Restore the scroll position
  // const scrollTop = parseInt(
  //   document.documentElement.style.getPropertyValue("--scroll-top"),
  //   10
  // );
  document.documentElement.classList.remove("no-scroll");
  // window.scrollTo({ top: scrollTop, behavior: "smooth" });
}
const smoothScrollToElement = (element) => {
  const duration = 700; // Set the duration for the smooth scroll
  const startTime = performance.now();

  element.scrollIntoView({ behavior: "smooth" });

  function checkScrollEnd(currentTime) {
    if (currentTime - startTime >= duration) {
      lockScroll();
    } else {
      requestAnimationFrame(checkScrollEnd);
    }
  }

  requestAnimationFrame(checkScrollEnd);
};
export const SearchOverlay = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const userUi = useUiStore();
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  const { locale } = useParams();
  const ui = useSearchUi();
  const { t } = useTranslation();
  React.useEffect(() => {
    if (
      ui.showSearch ||
      ui.showSubscriptions ||
      ui.showMenu ||
      ui.taxonomy !== null
    ) {
      if (scrollTop > 0) {
        const elem = document.getElementById("search");
        smoothScrollToElement(elem);
      } else lockScroll();
    }
    return unlockScroll;
  }, [ui.showSearch, ui.showSubscriptions, ui.showMenu, ui.taxonomy]);
  React.useEffect(() => {
    ui.setQuery({ page: 0 });
  }, [ui.showSearch, ui.query.term]);
  const searchQuery = useFetchSearchItems(ui.query, {
    enabled: ui.showSearch && ui.query.term.length > 0,
  });
  const subscriptionsQuery = useFetchTaxonSubscriptions({
    enabled: ui.showSubscriptions,
  });
  if (ui.showMenu)
    return (
      <MenuContainer title={t("search-where")} onClose={ui.closeMenu}>
        <MenuItem onClick={ui.openSearch}>{t("all")}</MenuItem>
        {userUi.signedIn && (
          <MenuItem
            icon={<FontAwesomeIcon icon={faFolderHeart} />}
            onClick={ui.openSubscriptions}
          >
            {t("taxon-subscriptions")}
          </MenuItem>
        )}
        <MenuItem
          icon={<TaxonomyIcon id="4" />}
          onClick={() => ui.pickTaxonomy("4")}
        >
          {t("origin")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="20" />}
          onClick={() => ui.pickTaxonomy("20")}
        >
          {t("genre")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="18" />}
          onClick={() => ui.pickTaxonomy("18")}
        >
          {t("publisher")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="6" />}
          onClick={() => ui.pickTaxonomy("6")}
        >
          {t("artist")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="5" />}
          onClick={() => ui.pickTaxonomy("5")}
        >
          {t("category")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="3" />}
          onClick={() => ui.pickTaxonomy("3")}
        >
          {t("brand")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="19" />}
          onClick={() => ui.pickTaxonomy("19")}
        >
          {t("product_line")}
        </MenuItem>
        <MenuItem
          icon={<TaxonomyIcon id="11" />}
          onClick={() => ui.pickTaxonomy("11")}
        >
          {t("character")}
        </MenuItem>
      </MenuContainer>
    );

  if (ui.taxonomy !== null) return <SearchTaxonomy />;
  if (ui.showSubscriptions)
    return (
      <MenuContainer
        title={t("taxon-subscriptions")}
        onClose={ui.closeSubscriptions}
      >
        <Flex
          onClick={() => {
            ui.closeSearch();
          }}
          as={Link}
          to={
            `/${locale}/products?` +
            new URLSearchParams({
              set_operation: "union",
              taxon_ids: subscriptionsQuery.data.taxons.map(
                (taxon) => taxon.id
              ),
            })
          }
          _hover={{ bg: hoverBg }}
          p={1}
          direction="row"
          gap={2}
          alignItems="center"
        >
          <Flex
            rounded="sm"
            flex="0 0 64px"
            bg="orange.500"
            color="white"
            height="64px"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon size="2x" icon={faFolders} />
          </Flex>
          <Box>
            <Heading noOfLines={1} as="div" size="sm">
              {t("show-all")}
            </Heading>
            <Box fontSize="sm">{t("taxon-subscriptions")}</Box>
          </Box>
        </Flex>
        {subscriptionsQuery.data.taxons
          .filter((t) => t.title.match(new RegExp(ui.query.term, "i")))
          .map((taxon) => (
            <TaxonItem taxon={taxon} key={taxon.id} ui={ui} />
          ))}
      </MenuContainer>
    );
  if (ui.showSearch && ui.query.term.length > 0)
    return (
      <MenuContainer title={t("search")} onClose={ui.closeSearch}>
        {searchQuery.data.results.map((item) => (
          <ResultItem item={item} key={item.id} />
        ))}
      </MenuContainer>
    );
};
