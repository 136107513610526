import {
  IconButton,
  InputGroup,
  Input,
  Box,
  InputLeftAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { faHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash-es/debounce";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetchTaxon } from "../api/queries";
import { useSearchUi } from "../hooks";
import { SearchOverlay } from "./SearchOverlay";
import { motion } from "framer-motion";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import qs from "query-string";

const taxonomies = {
  4: "origin",
  5: "category",
  3: "brand",
  19: "product_line",
  11: "character",
};

export const SearchButton = () => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchRef = React.useRef();
  const inputBg = "orange.50";
  const inputColor = "gray.800";
  const placeholderColor = "gray.300";
  const ui = useSearchUi();
  const query = ui.query;
  const { taxonId } = useParams();
  const taxonQuery = useFetchTaxon(
    taxonId,
    { view: "show" },
    {
      enabled: taxonId !== undefined,
    }
  );
  const debouncedSetTerm = debounce(
    (e) => ui.setQuery({ term: e.target.value }),
    300
  );
  React.useEffect(() => {
    searchRef.current.value = ui.query.term;
    if (
      ui.showSearch ||
      ui.showSubscriptions ||
      ui.taxonomy !== null ||
      ui.showMenu
    )
      searchRef.current.focus({ preventScroll: true });
  }, [ui.showSearch, ui.showSubscriptions, ui.taxonomy, ui.showMenu]);

  let searchOption;
  if (ui.showSubscriptions) searchOption = "taxon-subscriptions";
  else if (ui.taxonomy) searchOption = taxonomies[ui.taxonomy];
  else searchOption = "all";

  return (
    <>
      {(ui.showSearch ||
        ui.showSubscriptions ||
        ui.showMenu ||
        ui.taxonomy !== null) && (
        <Box
          backdropFilter="auto"
          backdropInvert="30%"
          backdropBlur="2px"
          onClick={ui.closeSearch}
          position="absolute"
          left="0"
          right="0"
          bottom="0"
          top="0"
          minHeight="120vh"
        ></Box>
      )}
      <InputGroup>
        <InputLeftAddon
          display="flex"
          direction="row"
          gap="2"
          position="relative"
          borderWidth="0"
          as="button"
          bg="orange.800"
          color="orange.50"
          onClick={ui.openMenu}
        >
          <FontAwesomeIcon icon={ui.showSubscriptions ? faHeart : faSearch} />
          {t(searchOption)}
        </InputLeftAddon>
        <Input
          id="header-search"
          borderWidth="0"
          ref={searchRef}
          defaultValue={query.term}
          placeholder={t("search")}
          _placeholder={{ color: placeholderColor }}
          onChange={debouncedSetTerm}
          onFocus={() =>
            ui.showSubscriptions === false &&
            ui.showMenu === false &&
            ui.taxonomy === null &&
            ui.openSearch()
          }
          inputMode="search"
          enterKeyHint="search"
          type="search"
          autoComplete="off"
          autoCorrect="off"
          as={motion.input}
          exit={{ width: 0 }}
          key="input"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
              navigate(
                `/${locale}/products?` +
                  new URLSearchParams({
                    term: e.target.value,
                    taxon_ids: taxonId ? [taxonQuery.data.id] : [],
                  })
              );
              ui.closeSearch();
            }
          }}
          bg={inputBg}
          color={inputColor}
          borderColor={inputBg}
          _hover={{ borderColor: "orange.700" }}
          focusBorderColor="orange.800"
        />
        <InputRightElement>
          <IconButton
            colorScheme="orange"
            bg="orange.800"
            size="sm"
            rounded="md"
            onClick={ui.closeSearch}
            aria-label="search results page"
            color="orange.50"
            as={Link}
            to={`/${locale}/products?` + qs.stringify(query)}
            icon={<FontAwesomeIcon icon={faArrowRight} />}
          />
        </InputRightElement>
      </InputGroup>
      <Box width="100%" position="relative">
        <SearchOverlay />
      </Box>
    </>
  );
};
