import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Badge,
  Heading,
  Image,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useUiStore } from "../hooks";
import {
  useFetchWatchLists,
  useFetchWatchList,
  useCreateWatchlistEntry,
} from "../api";
import { LoadingIndicator } from "../elements";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons/faGlobe";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faRankingStar } from "@fortawesome/pro-regular-svg-icons/faRankingStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WatchListPreview = ({ watch_list, onClose }) => {
  const ui = useUiStore();
  const { t } = useTranslation();
  const { locale } = useParams();
  const hoverBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");
  const watchListQuery = useFetchWatchList(
    watch_list.id,
    { product_id: ui.watchlistCandidate?.id },
    { enabled: ui.showWatchlistModal }
  );
  if (watchListQuery.isLoading) return <LoadingIndicator />;
  return (
    <Flex direction="column" gap={2}>
      <AnimatePresence>
        {watchListQuery.data.watch_list_entries.map((entry) => (
          <Flex
            as={motion.div}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            bg={
              entry.product.id === ui.watchlistCandidate?.id ? hoverBg : "unset"
            }
            alignItems="center"
            gap={2}
            key={entry.id}
          >
            <Box
              onClick={onClose}
              as={Link}
              flex="0 0 48px"
              to={entry.product.href}
            >
              <Image
                width="48px"
                height="48px"
                src={entry.product.profile_picture.cart.url}
              />
            </Box>
            <Heading noOfLines={1} size="sm" as="div">
              {entry.product.title}
            </Heading>
          </Flex>
        ))}
      </AnimatePresence>
      <Button
        onClick={onClose}
        as={Link}
        to={`/${locale}/user/watch-lists/${watch_list.id}`}
      >
        {watch_list.length > 8
          ? t("plus-more-products", { number: watch_list.length - 7 })
          : t("show")}
      </Button>
    </Flex>
  );
};

const icons = {
  ranking: faRankingStar,
  private: faLock,
  public: faGlobe,
};

const WatchListItem = ({ list, isSelected, select }) => {
  const selectedBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  const hoverBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");
  const bg = isSelected ? selectedBg : "inherit";
  const icon = icons[list.watch_list_type];

  return (
    <Flex
      alignItems="center"
      bg={bg}
      cursor="pointer"
      onClick={() => select(list)}
      _hover={{ bg: hoverBg }}
      gap={2}
      py={2}
    >
      <FontAwesomeIcon icon={icon} />
      <Box
        noOfLines={1}
        fontFamily="heading"
        letterSpacing="wider"
        fontWeight="500"
      >
        {list.name}
      </Box>
      <Box borderBottomWidth="2px" minWidth="10px" flex="1" />
      <Badge colorScheme={list.is_included ? "green" : "orange"}>
        {list.length}
      </Badge>
    </Flex>
  );
};

export const AddToWatchlist = () => {
  return <AddToWatchlistContent />;
};

const AddToWatchlistContent = () => {
  const [watchList, setWatchList] = React.useState(null);
  const addToWatchlist = useCreateWatchlistEntry();
  const ui = useUiStore();
  const watchListsQuery = useFetchWatchLists(
    { product_id: ui.watchlistCandidate?.id },
    { enabled: ui.showWatchlistModal }
  );
  const onClose = () => {
    setWatchList(null);
    ui.toggleWatchlistModal();
  };
  const { t } = useTranslation();
  return (
    <Modal
      preserveScrollBarGap
      size="2xl"
      onClose={ui.toggleWatchlistModal}
      isOpen={ui.showWatchlistModal}
    >
      <ModalOverlay />
      {ui.showWatchlistModal && (
        <ModalContent>
          <ModalHeader>
            {t("remember")}{" "}
            <Box color="gray.500" as="small">
              {ui.watchlistCandidate.title}
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody wrap="wrap" as={Flex} direction="row" gap={2}>
            <Box minWidth="250px" flex="1">
              <Heading as="div" size="md">
                {t("choose-watch-list")}
              </Heading>
              {watchListsQuery.data.watch_lists.map((list) => (
                <WatchListItem
                  list={list}
                  key={list.id}
                  select={setWatchList}
                  isSelected={watchList?.id === list.id}
                />
              ))}
            </Box>
            <Box position="relative" minWidth="250px" flex="1">
              {watchList && (
                <WatchListPreview watch_list={watchList} onClose={onClose} />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {t("close")}
            </Button>
            <Button
              isLoading={addToWatchlist.isPending}
              onClick={() =>
                addToWatchlist.mutate(
                  {
                    product_id: ui.watchlistCandidate.id,
                    watch_list_id: watchList.id,
                  },
                  {
                    onSuccess: () =>
                      setWatchList({ ...watchList, is_included: true }),
                  }
                )
              }
              isDisabled={watchList === null || watchList.is_included}
              type="submit"
              colorScheme="blue"
            >
              {t("add-to-watchlist")}
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};
