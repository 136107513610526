import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Circle } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "../elements";
import { useUiStore } from "../hooks";
import { faUserAlt } from "@fortawesome/pro-regular-svg-icons/faUserAlt";
import { faHomeUser } from "@fortawesome/pro-regular-svg-icons/faHomeUser";
import { useTranslation } from "react-i18next";

const scrollToMenu = () => {
  const elem = document.getElementById("user-menu");
  if (elem) {
    elem.scrollIntoView({ behavior: "smooth" });
  }
};

const HomeLink = ({ ui, t }) => {
  const { locale } = useParams();
  const location = useLocation();
  const isHome = !!location.pathname.match(new RegExp(`/${locale}/user`));

  return (
    <NavLink
      position="relative"
      as={Link}
      size="xl"
      onClick={scrollToMenu}
      to={isHome ? undefined : `/${locale}/user#user-menu`}
      leftIcon={<FontAwesomeIcon icon={faHomeUser} />}
    >
      {t("account")}
      {ui.downPayments > 0 && (
        <Circle
          fontSize="sm"
          fontWeight="300"
          position="absolute"
          bottom={ui.downPayments > 9 ? -6 : -5}
          right={8}
          bg="orange.500"
          d="flex"
          borderColor="orange.50"
          color="orange.50"
          alignItems="center"
          justifyContent="center"
          size={ui.downPayments > 9 ? "28px" : "24px"}
          borderWidth="2px"
        >
          {ui.downPayments}
        </Circle>
      )}
      {ui.incoming > 0 && (
        <Circle
          fontSize="sm"
          fontWeight="300"
          position="absolute"
          bottom={ui.incoming > 9 ? -6 : -5}
          right={3}
          bg="blue.500"
          d="flex"
          borderColor="blue.50"
          color="blue.50"
          alignItems="center"
          justifyContent="center"
          size={ui.incoming > 9 ? "28px" : "24px"}
          borderWidth="2px"
        >
          {ui.incoming}
        </Circle>
      )}
      {ui.mature > 0 && (
        <Circle
          fontSize="sm"
          fontWeight="300"
          position="absolute"
          bottom={ui.mature > 9 ? -6 : -5}
          right={-2}
          bg="teal.500"
          d="flex"
          borderColor="teal.50"
          color="teal.50"
          alignItems="center"
          justifyContent="center"
          size={ui.mature > 9 ? "28px" : "24px"}
          borderWidth="2px"
        >
          {ui.mature}
        </Circle>
      )}
    </NavLink>
  );
};

export const UserLink = () => {
  const { locale } = useParams();
  const ui = useUiStore();
  const { t } = useTranslation();
  if (ui.signedIn) {
    return <HomeLink ui={ui} locale={locale} t={t} />;
  } else
    return (
      <NavLink
        size="xl"
        as={Link}
        to={`/${locale}/accounts/sign-in`}
        leftIcon={<FontAwesomeIcon icon={faUserAlt} />}
      >
        {t("sign-in")}
      </NavLink>
    );
};
