import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDhl } from "@fortawesome/free-brands-svg-icons/faDhl";
import { faPaypal } from "@fortawesome/free-brands-svg-icons/faPaypal";
import { useTranslation } from "react-i18next";
import { Divider } from "../elements";
import { faStore } from "@fortawesome/pro-regular-svg-icons/faStore";
import { faMoneyBillTransfer } from "@fortawesome/pro-regular-svg-icons/faMoneyBillTransfer";

const PaymentItem = ({ type, icon }) => {
  const { t } = useTranslation();
  return (
    <Flex alignItems="center" direction="row" gap="2">
      <Box minWidth="48px">
        <FontAwesomeIcon size="2x" icon={icon} />
      </Box>
      <Heading size="sm" as="div">
        {t(type)}
      </Heading>
    </Flex>
  );
};

export function PaymentTypeSection() {
  const { t } = useTranslation();
  return (
    <Flex direction="column" gap="4" p={[2, 4]}>
      <Divider as="div">
        <Heading as="p" size="md">
          {t("payment-types")}
        </Heading>
      </Divider>
      <PaymentItem type="paypal" icon={faPaypal} />
      <PaymentItem type="banktransfer" icon={faMoneyBillTransfer} />
      <PaymentItem type="cash_on_delivery" icon={faDhl} />
      <PaymentItem type="store_berlin" icon={faStore} />
    </Flex>
  );
}
