import { useTranslation } from "react-i18next";
import { DefinitionLineItem, Price, Divider } from "../elements";

export const CartSummary = ({ cart }) => {
  const { t } = useTranslation();

  return (
    <>
      <DefinitionLineItem label={t("commodity-amount")}>
        {<Price price={cart.commodity_amount} />}
      </DefinitionLineItem>
      <DefinitionLineItem label={t("shipping-fee")}>
        {<Price price={cart.shipping_fee} />}
      </DefinitionLineItem>
      {cart.promotion && cart.promotion.amount !== 0.0 && (
        <DefinitionLineItem
          colorScheme="green"
          label={cart.promotion ? cart.promotion.message : t("discount")}
        >
          {Price({ price: cart.promotion.amount })}
        </DefinitionLineItem>
      )}
      <DefinitionLineItem label={t("total")}>
        {Price({ price: cart.total_amount })}
      </DefinitionLineItem>
      <Divider>{t("thereof")}</Divider>
      {cart.standard_tax > 0 && (
        <DefinitionLineItem
          label={t("tax-hint", {
            percentage: (cart.tax_zone.standard_rate * 100).toFixed(0),
          })}
        >
          {Price({ price: cart.standard_tax })}
        </DefinitionLineItem>
      )}
      {cart.reduced_tax > 0 && (
        <DefinitionLineItem
          label={t("tax-hint", {
            percentage: (cart.tax_zone.reduced_rate * 100).toFixed(0),
          })}
        >
          {Price({ price: cart.reduced_tax })}
        </DefinitionLineItem>
      )}
    </>
  );
};
