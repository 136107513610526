import { Box } from "@chakra-ui/react";

export const Price = ({ price }) => (
  <Box textAlign="right" as="nobr" minWidth="60px">
    {new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price)}
  </Box>
);
