import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

export const DefinitionItem = ({ label, sublabel, children, size = "md" }) => {
  const color = useColorModeValue("brown.600", "brown.50");
  const labelColor = useColorModeValue("brown.500", "brown.100");
  return (
    <Box fontSize={size} color={color}>
      <Box
        color={labelColor}
        fontFamily="heading"
        letterSpacing="wider"
        fontWeight="bold"
      >
        {label} {sublabel && <Box as="small">{sublabel}</Box>}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
