import { create } from "zustand";

export const useCheckoutStore = create((set, get) => ({
  billingAddress: null,
  shippingAddress: null,
  shipToDifferentAddress: false,
  paymentType: "",
  paypalData: null,
  toggleShipToDifferentAddress: () => set({ shipToDifferentAddress: !get().shipToDifferentAddress }),
  setPaymentId: (paymentId) => set({ paymentId }),
  setPaymentType: (paymentType) => set({ paymentType }),
  setBillingAddress: (billingAddress) => set({ billingAddress }),
  setShippingAddress: (shippingAddress) => set({ shippingAddress }),
  setPaypalData: paypalData => set({ paypalData }),
  setCheckout: ({ billing_address, shipping_address, payment_type, ship_to_different_address }) => set({
    paymentType: payment_type,
    billingAddress: billing_address,
    shippingAddress: shipping_address,
    shipToDifferentAddress: ship_to_different_address,
  })
}))

