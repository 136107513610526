import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Global, css } from "@emotion/react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { HelmetProvider } from "react-helmet-async";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import FiguyaRoutes from "./FiguyaRoutes";

const ErrorFallback = ({ error }) => {
  return (
    <div
      style={{ width: "1600px", margin: "auto", overflow: "scroll" }}
      role="alert"
    >
      <h1>Something went wrong:</h1>
      <p>{error.message}</p>
      <pre>{error.stack}</pre>
    </div>
  );
};

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    useErrorBoundary: (error) => error.response?.status >= 500,
    retry: (failureCount, error) => {
      if (error.cause >= 400 && error.cause < 500) return false;
      else if (failureCount < 2) return true;
      else return false;
    },
  },
};

const queryClient = new QueryClient({ defaultOptions: queryConfig });
function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <HelmetProvider>
              <Global
                styles={css`
                  :root {
                    --scroll-top: 8px;
                  }
                  html {
                    overflow-y: scroll;
                  }
                  html,
                  body {
                    height: 100%;
                    margin: 0;
                  }
                  .no-scroll {
                    overflow: hidden;
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    /* Keep the scroll position */
                    top: -webkit-calc(-1 * var(--scroll-top));
                    left: 0;
                  }
                  #figuya {
                    min-height: 100%;
                  }
                  img {
                    aspect-ratio: attr(width) / attr(height);
                  }
                  input#header-search[type="search"] {
                    -webkit-appearance: none;
                    border-left-radius: 0;
                    border-right-radius: 4px;
                  }
                  a[disabled] {
                    pointer-events: none !important;
                  }
                `}
              />
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <FiguyaRoutes />
              </QueryParamProvider>
            </HelmetProvider>
          </ChakraProvider>
        </BrowserRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
