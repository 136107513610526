import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useUiStore } from "../hooks";

export const MenuContainer = ({ children }) => {
  const ui = useUiStore();
  const ref = React.useRef();
  const bg = useColorModeValue("white", "gray.700");
  const overlayBg = useColorModeValue("blackAlpha.500", "whiteAlpha.500");
  return (
    <>
      <Box
        backdropFilter="auto"
        backdropInvert="30%"
        backdropBlur="2px"
        onClick={ui.closeMenus}
        zIndex="50"
        bg={overlayBg}
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        top="0"
      ></Box>
      <Box
        zIndex="51"
        width="100%"
        maxWidth="1400px"
        m="auto"
        position="relative"
      >
        <Box
          maxHeight="calc(100vh - 120px)"
          overflow="auto"
          left={0}
          right={0}
          as={motion.div}
          bg={bg}
          ref={ref}
          position="absolute"
          initial={{ scale: 0, opacity: 0, top: -50 }}
          animate={{
            scale: 1,
            opacity: 1,
            top: 8,
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            repeatType: "loop",
          }}
          zIndex="15"
          shadow="lg"
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
