import React from 'react';
import { Message } from '../elements';

function ProductShippingNotice({ stock_state }) {
  return stock_state === 'stocked' && (
    <Message
      color="green"
      body="Der Versand erfolgt innerhalb von 1-5 Tagen Nach Zahlungseingang."
    />
  );
}

export default ProductShippingNotice;
