import { Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFetchTaxonomies } from "../api/queries";
import { NavLink, LoadingIndicator } from "../elements";

export const Taxonomies = () => {
  const taxonomiesQuery = useFetchTaxonomies({ per: 50 });
  const { t } = useTranslation();
  if (taxonomiesQuery.isLoading) return <LoadingIndicator />;
  return (
    <Flex direction="column" gap="2">
      <Heading size="md">{t("our-taxonomies")}</Heading>
      {taxonomiesQuery.data.taxonomies.map((taxonomy) => (
        <NavLink key={taxonomy.id} to={taxonomy.href}>
          {taxonomy.title}
        </NavLink>
      ))}
    </Flex>
  );
};
