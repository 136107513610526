import React from "react";
import { Outlet, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import { useColorModeValue, Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useSearchUi, useUiStore } from "../hooks";
import useDimensions from "react-cool-dimensions";
import { NewReservation } from "../products";
import { JoinWaitlist } from "../products/JoinWaitlist";
import { AddToWatchlist } from "../products/AddToWatchlist";
import { useTranslation } from "react-i18next";
import { Footer } from "../footer/Footer";
import { useFetchUserSignedIn } from "../api";
import CookieConsent from "../CookieConsent";

export const MainLayout = () => {
  const { observe, width } = useDimensions();
  const mainRef = React.useRef();
  const location = useLocation();
  const ui = useUiStore();
  const searchUi = useSearchUi();
  const { i18n } = useTranslation("shop");
  const { locale } = useParams();
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!["de", "en"].includes(locale))
      return navigate("/de")
  }, [locale])
  const signedInQuery = useFetchUserSignedIn();
  React.useEffect(() => {
    if (signedInQuery.isSuccess) {
      ui.setSignedIn(signedInQuery.data.signed_in);
      ui.setMatureCount(signedInQuery.data.mature);
      ui.setIncomingCount(signedInQuery.data.incoming);
      ui.setDownPaymentsCount(signedInQuery.data.down_payments);
    }
  }, [signedInQuery.isFetching])
  React.useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);
  React.useEffect(() => {
    if (mainRef.current) {
      ui.setMainRef(mainRef);
    }
  }, [mainRef.current]);
  React.useEffect(() => {
    ui.setWidth(width);
  }, [width]);
  const type = useNavigationType();

  React.useEffect(() => {
    if (
      searchUi.showTaxons === false &&
      searchUi.showSearch === false &&
      searchUi.showSubscriptions === false
    )
      if (type !== "POP") window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname, location.search]);
  const contentBg = useColorModeValue("white", "gray.800");
  if (!["de", "en"].includes(locale)) return null
  return (
    <>
      <NewReservation />
      <JoinWaitlist />
      <AddToWatchlist />
      <Box ref={mainRef} />
      <Header />
      <Flex position="relative" m="auto" maxWidth="1400px" direction={["column", "column", "row"]}>
        <Box
          as="main"
          id="main-container"
          bg={contentBg}
          minWidth={["100%", "100%", "500px"]}
          minHeight="100vh"
          position="relative"
          flex="1"
          ref={observe}
          px={[2, 4]}
          py={4}
        >
          <CookieConsent>
            <Outlet />
          </CookieConsent>
        </Box>
        <Sidebar />
      </Flex>
      <Footer />
    </>
  );
};
