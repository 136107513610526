import { Box, Image, Flex } from "@chakra-ui/react";
import { Headline } from "../elements";
import { useTranslation } from "react-i18next";
import error from "./error.jpg";

export const ServerError = () => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100%"
      direction="column"
      gap={4}
    >
      <Headline title="5xx" subtitle={t("error")} />
      <Box borderWidth="2px">
        <Image src={error} />
      </Box>
    </Flex>
  );
};
