import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  LinkBox,
  LinkOverlay,
  Heading,
  Image,
  Flex,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

export const TaxonCard = ({ taxon }) => {
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
  const [first, ...products] = taxon.products;
  const firstSrc = useBreakpointValue({ base: first?.src, lg: first?.large });
  const firstSize = useBreakpointValue({ base: 84 });
  return (
    <LinkBox bg={bg} p="2">
      <Flex gap="2" direction="row" key={taxon.id}>
        {first && (
          <Box>
            <Image
              htmlWidth={firstSize}
              htmlHeight={firstSize}
              src={firstSrc}
            />
          </Box>
        )}
        <Flex direction="column" wrap="wrap" gap="2">
          <Heading flex="1" size="sm">
            <LinkOverlay as={Link} to={taxon.href} noOfLines={2}>
              {taxon.title}
            </LinkOverlay>
          </Heading>
          <Flex flex="1" direction="row" gap="1">
            {products.map((p) => (
              <Image key={p.src} htmlWidth="48" htmlHeight="48" src={p.src} />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </LinkBox>
  );
};
