import { Flex, useColorModeValue } from "@chakra-ui/react";

export const Quote = ({ children }) => {
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.50");
  return (
    <Flex direction="column" gap="2" borderLeftWidth="4px" bg={bg} p="4">
      {children}
    </Flex>
  );
};
