import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { DisplayProductQuery } from "./DisplayProductQuery";
import { useTranslation } from "react-i18next";

export const Products = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t(`products-meta_index`)}</title>
        <link
          rel="alternate"
          href={`https://figuya.com/en/products${location.search}`}
          hrefLang="en"
        />
        <link
          rel="alternate"
          href={`https://figuya.com/de/produkte${location.search}`}
          hrefLang="de"
        />
        <link
          rel="alternate"
          href={`https://figuya.com/de/produkte${location.search}`}
          hrefLang="x-default"
        />
        <meta name="description" content={t(`products-meta_description`)} />
      </Helmet>
      <DisplayProductQuery />
    </>
  );
};
