import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Square,
  Tooltip,
  ButtonGroup,
  Image,
  LinkBox,
  LinkOverlay,
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { ProductState } from "./ProductState";
import { ProductPrice } from "./ProductPrice";
import { Availability } from "./availability";
import ImagePreview from "./image-preview";
import { useHover } from "../hooks";
import { Blurhash } from "react-blurhash";
import { ProductActionMenu } from "./ProductActionMenu";
import { AddToCartButton } from "./AddToCartButton";
import { ReserveButton } from "./ReserveButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercentage } from "@fortawesome/pro-regular-svg-icons";

export const ProductCard = ({ product, href = null, width = 260 }) => {
  const { taxonId } = useParams();
  const [ref, isHovered] = useHover();
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const {
    title,
    profile_picture,
    slug,
    base_price,
    gross_selling_price,
    base_price_required,
  } = product;
  return (
    <Tooltip
      width={`${width + 20}px`}
      openDelay={500}
      hasArrow
      placement="top"
      label={product.title}
    >
      <LinkBox
        display="flex"
        flexDirection="column"
        flex="1"
        as="article"
        ref={ref}
      >
        <Box aspectRatio="1" position="relative">
          {product.discount_percentage !== 0 && (
            <Flex
              direction="row"
              position="absolute"
              top={-1}
              left={-1}
            >
              <Box
                lineHeight="1"
                p={2}
                borderWidth="2px"
                borderColor="red.100"
                bg="red.600"
                color="red.50"
              >
                <Flex gap="1" alignItems="center">
                  {product.discount_percentage}{" "}
                  <FontAwesomeIcon size="xs" icon={faPercentage} />
                  <Box fontSize="xs">Sparen</Box>
                </Flex>
                <Box fontSize="xs" fontWeight="semibold" color="red.100">Zeitlich begrenzt</Box>
              </Box>
            </Flex>
          )}
          <ImagePreview isHovered={isHovered} product={product} />

          {product.blurhash && (
            <Blurhash
              hash={product.blurhash}
              width={width}
              height={width}
              style={{ width: '100%', height: '100%', display: isImageLoaded ? "none" : "block" }}

            />
          )}
          <Image
            onLoad={() => setImageLoaded(true)}
            roundedTop="sm"
            bg="gray.100"
            display={isImageLoaded || !product.blurhash ? "block" : "none"}
            alt={title}
            src={profile_picture.small_profile.url}
            srcSet={
              profile_picture.small_profile_webp &&
              profile_picture.large_profile_webp &&
              `${profile_picture.small_profile_webp?.url} 200w, ${profile_picture.large_profile_webp?.url} 285w`
            }
          />
          <ProductState
            position="absolute"
            roundedTopLeft="none"
            roundedBottomRight="none"
            top={0}
            right={0}
            product={product}
          />
          <Availability
            position="absolute"
            availability={product.availability}
            bottom={0}
            left={0}
            roundedBottom={0}
            roundedTopLeft={0}
          />
        </Box>
        <Flex direction="column" gap={2} flex="auto 1 1" py={2}>
          <Heading mb="auto" size="xs">
            <LinkOverlay
              noOfLines={
                base_price_required || base_price !== gross_selling_price
                  ? 1
                  : 2
              }
              as={Link}
              to={href ? href : taxonId ? `products/${slug}` : slug}
            >
              {title}
            </LinkOverlay>
          </Heading>
          <Box fontWeight="semibold" color="gray.500">{product.brand ? product.brand : product.publisher}</Box>
          <ProductPrice split={false} product={product} />
        </Flex>
        <ButtonGroup isAttached>
          {["preorder", "order_stop"].includes(product.stock_state) ? (
            <ReserveButton flex="1" size="md" product={product} />
          ) : (
            <AddToCartButton flex="1" size="md" product={product} />
          )}
          <ProductActionMenu
            colorScheme={
              ["preorder", "order_stop"].includes(product.stock_state)
                ? "purple"
                : "orange"
            }
            size="md"
            product={product}
          />
        </ButtonGroup>
      </LinkBox>
    </Tooltip>
  );
};
