import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Flex,
  Circle,
  Image,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { useUiStore } from "../hooks";
import { LoadingIndicator, DefinitionItem, AcceptTerms } from "../elements";
import { ProductPrice } from "./ProductPrice";
import { useTranslation } from "react-i18next";
import { useCreateWaitlisting, useFetchProduct } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import dayjs from "dayjs";

export const JoinWaitlist = () => {
  const createWaitlisting = useCreateWaitlisting();
  const { t } = useTranslation();
  const { locale } = useParams();
  const checkboxRef = React.useRef();
  const joinWaitlist = (e) => {
    e.preventDefault();
    createWaitlisting.mutate({ product_id: ui.waitlistCandidate.id });
  };
  const ui = useUiStore();
  const productQuery = useFetchProduct(ui.waitlistCandidate?.slug, {
    enabled: ui.showWaitlistModal,
  });
  return (
    <Modal
      preserveScrollBarGap
      size="2xl"
      onClose={ui.toggleWaitlistModal}
      isOpen={ui.showWaitlistModal}
      initialFocusRef={checkboxRef}
    >
      <ModalOverlay />
      {ui.showWaitlistModal && (
        <form onSubmit={joinWaitlist}>
          <ModalContent minHeight="200px">
            {productQuery.isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <ModalHeader>
                  Auf die Warteliste{" "}
                  <Box color="gray.500" as="small">
                    {ui.waitlistCandidate.title}
                  </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex direction="column" gap={2}>
                    <Flex
                      wrap="wrap"
                      gap={2}
                      alignItems="center"
                      direction="row"
                    >
                      <Box>
                        <Link
                          onClick={ui.toggleWaitlistModal}
                          to={ui.waitlistCandidate.href}
                        >
                          <Image
                            htmlWidth="128"
                            htmlHeight="128"
                            rounded="md"
                            src={ui.waitlistCandidate.profile_picture.thumb.url}
                          />
                        </Link>
                      </Box>
                      <Flex flex="2" direction="column" gap={2}>
                        <DefinitionItem label="Aktueller Preis">
                          <ProductPrice
                            split={false}
                            product={ui.waitlistCandidate}
                          />
                        </DefinitionItem>
                        {productQuery.data.wait_list_item && (
                          <DefinitionItem label={t("waitlisted")}>
                            <Flex alignItems="center" direction="row" gap="2">
                              <Circle
                                size="24px"
                                color="blue.700"
                                borderColor="blue.600"
                                borderWidth="2px"
                                bg="blue.50"
                              >
                                <FontAwesomeIcon size="xs" icon={faCheck} />
                              </Circle>
                              {dayjs(
                                productQuery.data.wait_list_item.created_at
                              ).format("L")}
                            </Flex>
                          </DefinitionItem>
                        )}
                      </Flex>
                    </Flex>
                    {!productQuery.data.wait_list_item && (
                      <>
                        <Box
                          fontSize="lg"
                          fontFamily="heading"
                          fontWeight="500"
                          letterSpacing="wider"
                        >
                          {t("join-waitlist")}
                        </Box>
                        <AcceptTerms link={`/${locale}/info/terms`} />
                      </>
                    )}
                  </Flex>
                </ModalBody>

                <ModalFooter>
                  <Button
                    variant="ghost"
                    mr={3}
                    onClick={ui.toggleWaitlistModal}
                  >
                    {t("close")}
                  </Button>

                  {productQuery.data.wait_list_item ? (
                    <Button
                      onClick={ui.toggleWaitlistModal}
                      colorScheme="blue"
                      variant="ghost"
                      as={Link}
                      to={`/${locale}/user/wait-list-items`}
                    >
                      {t("wait-list-items")}
                    </Button>
                  ) : (
                    <Button
                      isLoading={createWaitlisting.isPending}
                      type="submit"
                      colorScheme="blue"
                    >
                      {t("create-waitlisting")}
                    </Button>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </form>
      )}
    </Modal>
  );
};
