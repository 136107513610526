import {
  Alert,
  AlertIcon,
  AlertDescription,
  useColorModeValue,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export const AcceptTerms = ({ label = "accept-terms", link }) => {
  const iconBg = useColorModeValue("green.200", "green.500");
  const { t } = useTranslation();

  return (
    <Alert status="success">
      <AlertIcon
        p="2"
        width="32px"
        height="32px"
        bg={iconBg}
        rounded="full"
        as={FontAwesomeIcon}
        icon={faCheck}
      />
      <AlertDescription>
        {<Trans t={t} i18nKey={label} components={[<Link to={link} />]} />}
      </AlertDescription>
    </Alert>
  );
};
