import { create } from "zustand";

const defaultSearchQuery = {
  page: 1,
  term: "",
  order: "touched_at",
  direction: "desc",
  stock_state: ["stocked", "backorder", "preorder"],
  availability: [],
  taxon_ids: [],
};

export const useSearchUi = create((set, get) => ({
  showSidebar: false,
  query: defaultSearchQuery,
  showSearch: false,
  showTaxons: false,
  showSubscriptions: false,
  taxonomy: null,
  showMenu: false,
  toggleSidebar: () => set({ showSidebar: !get().showSidebar }),
  pickTaxonomy: (taxonomy) =>
    set({
      taxonomy,
      showSearch: false,
      showMenu: false,
      showSubscriptions: false,
      query: { ...get().query, term: "" },
    }),
  openMenu: () =>
    set({ showMenu: true, showSearch: false, showSubscriptions: false }),
  openSearch: () =>
    set({
      showSearch: true,
      showSubscriptions: false,
      showMenu: false,
      taxonomy: null,
    }),
  openSubscriptions: () =>
    set({
      showSubscriptions: true,
      showSearch: false,
      showMenu: false,
      query: { ...get().query, term: "" },
    }),
  closeSearch: () =>
    set({
      showSearch: false,
      showSubscriptions: false,
      showMenu: false,
      taxonomy: null,
    }),
  setQuery: (query) => set({ query: { ...get().query, ...query } }),
}));

export const useUiStore = create((set, get) => ({
  mature: 0,
  incoming: 0,
  width: 0,
  downPayments: 0,
  guestOrder: null,
  signedIn: false,
  showMangaMenu: false,
  showBrowseMenu: false,
  showTaxonSubscriptionsMenu: false,
  showTaxonMenu: false,
  showCartPopover: false,
  selectedTaxon: null,
  showReservationModal: false,
  reservationCandidate: null,
  showWaitlistModal: false,
  waitlistCandidate: null,
  showWatchlistModal: false,
  watchlistCandidate: null,
  mainRef: null,
  sideRef: null,
  setMainRef: (mainRef) => set({ mainRef }),
  scrollToSideTop: () => { },
  scrollToMainTop: () => {
    const ref = get().mainRef;
    if (ref && ref.current) {
      // ref.current.scrollIntoView();
    }
  },
  setGuestOrder: (guestOrder) => set({ guestOrder }),
  setSignedIn: (signedIn) => set({ signedIn }),
  setWidth: (width) => set({ width }),
  closeAllMenus: () =>
    set({
      showMangaMenu: false,
      showBrowseMenu: false,
      showTaxonMenu: false,
      selectedTaxon: false,
    }),
  setMatureCount: (count) => set({ mature: count }),
  setIncomingCount: (count) => set({ incoming: count }),
  setDownPaymentsCount: (count) => set({ downPayments: count }),
  enableCartPopover: () => set({ showCartPopover: true }),
  disableCartPopover: () => set({ showCartPopover: false }),
  toggleCartPopover: () => set({ showCartPopover: !get().showCartPopover }),
  toggleSidebar: () => set({ showSidebar: !get().showSidebar }),
  toggleMangaMenu: () => set({ showMangaMenu: !get().showMangaMenu }),
  toggleBrowseMenu: () => set({ showBrowseMenu: !get().showBrowseMenu }),
  toggleTaxonSubscriptionsMenu: () =>
    set({ showTaxonSubscriptionsMenu: !get().showTaxonSubscriptionsMenu }),
  toggleWatchlistModal: (product) => {
    const nextShowWatchlistModal = !get().showWatchlistModal;
    return set({
      showWatchlistModal: nextShowWatchlistModal,
      watchlistCandidate: nextShowWatchlistModal ? product : null,
    });
  },
  toggleWaitlistModal: (product) => {
    const nextShowWaitlistModal = !get().showWaitlistModal;
    return set({
      showWaitlistModal: nextShowWaitlistModal,
      waitlistCandidate: nextShowWaitlistModal ? product : null,
    });
  },
  setReservationCandidate: (product) => set({ reservationCandidate: product }),
  toggleReservationModal: (product) => {
    const nextShowReservationModal = !get().showReservationModal;
    return set({
      showReservationModal: product ? nextShowReservationModal : false,
      reservationCandidate: nextShowReservationModal ? product : null,
    });
  },
  toggleTaxonMenu: (taxon) => {
    const nextShowTaxonMenu = !get().showTaxonMenu;
    return set({
      showTaxonMenu: nextShowTaxonMenu,
      selectedTaxon: nextShowTaxonMenu ? taxon : null,
    });
  },
  closeMenus: () =>
    set({
      showTaxonSubscriptionsMenu: false,
      showTaxonMenu: false,
      showMangaMenu: false,
      showBrowseMenu: false,
    }),
}));
