import styled from "@emotion/styled";

export const ResponsiveTableContainer = styled.div`
  tr {
    padding: 16px 4px;
    gap: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-width: 1px;
  }
  th {
    padding: 2px 4px;
    flex: 1;
    min-width: 180px;
  }
  td {
    padding: 2px 4px;
    flex: 4;
    min-width: 250px;
  }
  th,
  td {
    border-width: 0px;
  }
`;
