import React from "react";
import { Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const stateColors = {
  sold_out: "red",
  order_stop: "pink",
  placed: "purple",
  mature: "teal",
  stocked: "green",
  preorder: "violet",
  backorder: "blue",
  incoming: "blue",
};

export const ProductState = ({ product, ...props }) => {
  const { t } = useTranslation();
  const state = product.reservation
    ? product.reservation.state
    : product.stock_state;
  return (
    <Badge variant="solid" colorScheme={stateColors[state]} {...props}>
      {t(state)}
    </Badge>
  );
};
