import React from "react";
import { Price } from "../elements";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";

export const ProductPrice = ({
  id,
  product,
  children,
  flexGrow = 0,
  taxHint = true,
  split = true,
}) => {
  const color = useColorModeValue("gray.600", "gray.200");
  return (
    <Box flexGrow={flexGrow}>
      {parseFloat(product.base_price) > parseFloat(product.price) ? (
        <>
          <Text fontSize="12px" as="del" mr={1}>
            <Price price={product.base_price} style="currency" currency="EUR" />
          </Text>
          <Text
            as="mark"
            fontWeight="semibold"
            fontSize="14px"
            p={1}
            mr={1}
            bg="red.400"
            borderColor="red.600"
            color="red.50"
            borderBottomWidth="1px"
          >
            <Price
              price={product.price}
              style="currency"
              currency="EUR"
            />
          </Text>
        </>
      ) : product.reservation &&
        parseFloat(product.reservation.effective_price) <
        parseFloat(product.base_price) ? (
        <>
          <Text fontSize="12px" as="del" mr={1}>
            <Price price={product.base_price} style="currency" currency="EUR" />
          </Text>
          <Text
            as="mark"
            whileInView={{ scale: 1.1 }}
            fontSize="14px"
            px={1}
            mr={1}
            bg="red.400"
            borderColor="red.600"
            color="red.50"
            borderBottomWidth="1px"
          >
            <Price
              price={product.reservation.effective_price}
              style="currency"
              currency="EUR"
            />
          </Text>
        </>
      ) : (
        <Text fontWeight="semibold" size="xs" as="span" mr={1}>
          <Price price={product.price} style="currency" currency="EUR" />
        </Text>
      )}
      {taxHint && (
        <>
          <Text fontSize="10px" as="small" mr={1}>
            {product.tax_class.description}
          </Text>
        </>
      )}
      {children}
      {taxHint && product.base_price_required && product.weight < 250 && (
        <Box fontSize="10px">
          <Text color={color} as="small">
            <Price
              price={(product.price / product.weight) * 100}
              style="currency"
              currency="EUR"
            />{" "}
            / g
          </Text>
        </Box>
      )}
      {taxHint && product.base_price_required && product.weight >= 250 && (
        <Box fontSize="10px">
          <Text color={color} as="small">
            <Price
              price={(product.price / product.weight) * 1000}
              style="currency"
              currency="EUR"
            />{" "}
            / Kg
          </Text>
        </Box>
      )}
    </Box>
  );
};
