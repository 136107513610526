import React from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Image,
  Heading,
  LinkBox,
  LinkOverlay,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { ProductState } from "./ProductState";
import { ProductPrice } from "./ProductPrice";
import { ProductActionMenu } from "./ProductActionMenu";
import { Blurhash } from "react-blurhash";
import { motion } from "framer-motion";
import { VolumeItem } from "../products/VolumeItem"

export const ProductListItem = ({ product, sidebar = false }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const hoverBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");
  const evenBg = useColorModeValue("gray.100", "gray.600");
  return (
    <LinkBox
      p="1"
      _hover={{ bg: hoverBg }}
      _even={{ bg: evenBg }}
      gap={2}
      display="flex"
      flexDirection="row"
      title={product.title}
      as={motion.article}
      layout
      alignItems="center"
      justifyContent="center"
    >
      <Box position="relative" flex="48px 0 0">
        <Image
          display={isImageLoaded || !product.blurhash ? "block" : "none"}
          onLoad={() => setImageLoaded(true)}
          htmlWidth="48"
          htmlHeight="48"
          src={product.profile_picture.preview.url}
          srcSet={
            product.profile_picture.preview_webp &&
            product.profile_picture.profile_webp &&
            `${product.profile_picture.preview_webp.url}, ${product.profile_picture.profile_webp.url} 2x`
          }
          alt={product.title}
        />
        {product.blurhash && (
          <Blurhash
            hash={product.blurhash}
            width={48}
            height={48}
            style={{ display: isImageLoaded ? "none" : "block" }}
          />
        )}
      </Box>
      {product.volume && <VolumeItem id={product.id} stock_state={product.stock_state} volume={product.volume} />}
      <Flex
        alignItems={["flex-start", sidebar ? "flex-start" : "center"]}
        gap={[0, sidebar ? 0 : 2]}
        direction={["column", sidebar ? "column" : "row"]}
      >
        <Box>
          <ProductState product={product} />
        </Box>
        <Heading size="sm" as="p" overflow="hidden" noOfLines={1}>
          <LinkOverlay as={Link} to={product.href}>
            {product.title}
          </LinkOverlay>
        </Heading>
      </Flex>
      <Box minWidth="10px" flex="1" borderBottomWidth="2px" />
      <ProductPrice split={false} product={product} taxHint={false} />
      <ProductActionMenu product={product} />
    </LinkBox>
  );
};
