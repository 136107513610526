import React from "react";
import { Image, Stack, Circle, Box, Flex } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useFetchImages } from "../api";
import { LoadingSidebarIndicator } from "../elements";
import { Link } from "react-router-dom";

const LoadingBar = ({ loading }) => {
  const loadingBar = {
    start: {
      width: 0,
    },
    end: {
      width: "100%",
      transition: {
        duration: 0.5, // Set fixed loading duration to 5 seconds
        ease: "linear",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5, // Set exit (cancel) animation duration
      },
    },
  };

  return (
    <AnimatePresence>
      {loading && (
        <Box
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          as={motion.div}
          h="4px"
          bg="orange.600"
          initial="start"
          animate="end"
          exit="exit"
          variants={loadingBar}
        />
      )}
    </AnimatePresence>
  );
};

const IndexSelector = ({ product, index, select }) => {
  return (
    <Circle
      as={Link}
      to={`${product.href}?image=${index}`}
      onMouseEnter={() => select(index)}
      borderWidth="2px"
      borderColor="whiteAlpha.500"
      bg="blackAlpha.900"
      size="20px"
    />
  );
};

const ImagePreview = ({ product, isHovered = false }) => {
  const [shouldFetchImages, setShouldFetchImages] = React.useState(false);
  React.useEffect(() => {
    let timerId;
    if (isHovered) {
      timerId = setTimeout(() => {
        if (isHovered) {
          // Value is now true, execute the callback
          setShouldFetchImages(true);
        }
      }, 500);
    } else {
      setShouldFetchImages(false);
    }
    return () => {
      // Clean up the timer when the component unmounts or the value changes
      setShouldFetchImages(false);
      clearTimeout(timerId);
    };
  }, [isHovered]);
  const imagesQuery = useFetchImages(product.id, {
    enabled: shouldFetchImages,
  });
  const [imageIndex, setImageIndex] = React.useState(
    null,
    (x) => x % imagesQuery.data.length
  );
  return (
    <>
      <LoadingBar
        loading={
          isHovered && !shouldFetchImages && !imagesQuery.isFetchedAfterMount
        }
      />
      {imagesQuery.isLoading && <LoadingSidebarIndicator />}
      <Box
        position="absolute"
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{
          zIndex: imageIndex === null ? 0 : 1,
          opacity: imageIndex === null ? 0 : 0.5,
        }}
        left={0}
        right={0}
        top={0}
        bottom={0}
        bg="black"
      />
      {imageIndex !== null && (
        <Box
          zIndex="2"
          position="absolute"
          shadow="lg"
          top={8}
          left={0}
          right={0}
        >
          <Image
            maxWidth="100%"
            m="auto"
            src={imagesQuery.data[imageIndex].file.thumb.url}
          />
        </Box>
      )}
      <Flex
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        justifyContent="center"
        alignItems="center"
        zIndex="3"
        top={2}
        left={2}
        right={2}
        position="absolute"
      >
        <Flex
          direction="row"
          wrap="wrap"
          gap="1"
          onMouseLeave={() => setImageIndex(null)}
        >
          {imagesQuery.data.slice(0, 7).map((image, index) => (
            <IndexSelector
              product={product}
              key={image.id}
              index={index}
              select={setImageIndex}
            />
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default ImagePreview;
