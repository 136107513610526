import React from "react";
import {
  Box,
  Heading,
  Skeleton,
  Flex,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";

export const Headline = ({
  tag = null,
  title,
  subtitle,
  children,
  color = "orange.500",
  button,
  ...props
}) => {
  const lighterColor = useColorModeValue("brown.500", "brown.200");
  const size = useBreakpointValue({ base: "lg", lg: "xl" }, { ssr: false });
  return (
    <Box
      width="100%"
      borderColor={color}
      borderBottomWidth="4px"
      as="header"
      {...props}
    >
      <Flex
        alignItems="center"
        display="flex"
        gap="2"
        direction="row"
        wrap="wrap"
      >
        <Heading minWidth="300px" flex="1" as="h1" size={size}>
          {title.length > 0 ? (
            title
          ) : (
            <Skeleton>Figuren Sammler kaufen bei Figuya</Skeleton>
          )}{" "}
          <Box as="small" color={lighterColor}>
            {subtitle}
          </Box>
        </Heading>
        {button}
      </Flex>
      <Box fontFamily="heading" letterSpacing="wider" fontSize="lg">
        {children}
      </Box>
    </Box>
  );
};
