import { Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"
import { useFetchQuestion } from "../api";
import { Description, Headline, LoadingIndicator } from "../elements";

export const Question = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const questionQuery = useFetchQuestion(id);
  if (questionQuery.isLoading) return <LoadingIndicator />
  return <Flex direction="column" gap="2">
    <Headline title={questionQuery.data.question} subtitle={t(questionQuery.data.topic)} />
    <Description>
      {questionQuery.data.answer}
    </Description>
  </Flex>
}
