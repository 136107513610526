import React from "react";
import { Link } from "react-router-dom";
import {
  LinkBox,
  LinkOverlay,
  Tooltip,
  Image,
  Box,
  Flex,
  Heading,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { Blurhash } from "react-blurhash";
import { ProductState } from "./ProductState";
import { ProductActionMenu } from "./ProductActionMenu";
import { ProductPrice } from "./ProductPrice";

export const ProductGridItem = ({ product, cardWidth = 200 }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const priceBg = useColorModeValue("whiteAlpha.800", "blackAlpha.700");
  return (
    <LinkBox
      as="article"
      display="flex"
      gap="1"
      flexDirection="column"
    >
      <Tooltip placement="top" label={product.title}>
        <Box position="relative">
          {product.blurhash && (
            <Blurhash
              hash={product.blurhash}
              width={cardWidth}
              height={cardWidth}
              style={{ display: isImageLoaded ? "none" : "block" }}
            />
          )}
          <Image
            display={isImageLoaded || !product.blurhash ? "block" : "none"}
            onLoad={() => setImageLoaded(true)}
            htmlWidth="200"
            htmlHeight="200"
            src={product.profile_picture.small_profile.url}
          />
          <ProductState
            roundedRight="0"
            position="absolute"
            top="0"
            right="0"
            product={product}
          />
        </Box>
      </Tooltip>
      <Flex gap="2" alignItems="center" direction="row">
        <Heading overflow="hidden" size="sm" noOfLines={1} as="div">
          <LinkOverlay as={Link} overflow="hidden" to={product.href}>
            {product.title}
          </LinkOverlay>
        </Heading>
      </Flex>
      <Flex gap="2" alignItems="center" direction="row">
        <ProductPrice taxHint={false} product={product} />
        <Box flex="1" />
        <ProductActionMenu size="xs" product={product} />
      </Flex>
    </LinkBox>
  );
};
