import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const white = "#fffaef";

const black = "#1c1c1c";

// T9is is the default breakpoint
export const breakpoints = {
  sm: "35em",
  md: "45em",
  lg: "65em",
  xl: "75em",
  "2xl": "1415px",
};

const figuyaTheme = extendTheme({
  breakpoints,
  styles: {
    global: (props) => ({
      body: {
        color: mode("gray.600", "white")(props),
        bg: mode("white", "gray.800")(props),
      },
      "*, *::before, &::after": {
        borderColor: mode("gray.300", "gray.700")(props),
      },
    }),
  },
  config: {
    initialColorMode: "light",
  },
  components: {
    Modal: {
      baseStyle: (props) => ({
        overlay: {
          backdropFilter: "auto",
          backdropInvert: "40%",
          backdropBlur: "2px",
        },
        dialog: {
          borderRadius: 0,
          shadow: "lg",
        },
      }),
    },
    Popover: {
      baseStyle: (props) => ({
        content: {
          borderRadius: 0,
          shadow: "lg",
        },
        header: {
          borderWidth: 0,
        },
        footer: {
          borderWidth: 0,
        },
      }),
    },
    Drawer: {
      baseStyle: (props) => ({
        dialog: {
          bg: mode("white", "gray.800")(props),
        },
      }),
    },
    FormLabel: {
      baseStyle: {
        mb: "0",
      },
    },
    Tag: {
      baseStyle: {
        container: {
          rounded: "sm",
        },
      },
    },
    Button: {
      baseStyle: {
        rounded: "sm",
      },
      variants: {
        header: {
          bg: "orange.500",
          color: "orange.50",
        },
      },
    },
  },
  fonts: {
    heading: '"PT Sans", sans-serif',
    body: '"PT Sans", system-ui, sans-serif',
    mono: "Menlo, monospace",
  },
  colors: {
    primary: "#fd7e14",
    white,
    black,
    link: "#339af0",
    blackAlpha: {
      50: "rgba(73, 80, 87, 0.02)", // Sehr subtil für Hintergründe
      100: "rgba(73, 80, 87, 0.04)", // Leichte Anpassung für feine Linien
      200: "rgba(73, 80, 87, 0.06)", // Geringe Sichtbarkeit für Umrisse
      300: "rgba(73, 80, 87, 0.1)",  // Angepasst für Textschatten oder feine Details
      400: "rgba(73, 80, 87, 0.15)", // Mittlere Transparenz für Trennlinien
      500: "rgba(73, 80, 87, 0.25)", // Klarer für Text und Icons
      600: "rgba(73, 80, 87, 0.35)", // Dunkler für hervorgehobene Texte
      700: "rgba(73, 80, 87, 0.5)",  // Stärker für Überschriften
      800: "rgba(73, 80, 87, 0.7)",  // Sehr dunkel für wichtige UI-Elemente
      900: "rgba(73, 80, 87, 0.85)", // Fast opak für kritische Interaktionselemente
    },
    whiteAlpha: {
      50: "rgba(249, 248, 244, 0.04)", // Sehr leichte Transparenz für sehr subtile Hintergründe
      100: "rgba(249, 248, 244, 0.06)", // Leichte Anpassung für geringfügig deutlichere Hintergründe
      200: "rgba(249, 248, 244, 0.08)", // Minimal erhöhte Sichtbarkeit
      300: "rgba(249, 248, 244, 0.12)", // Anpassung für mittlere Transparenz
      400: "rgba(249, 248, 244, 0.2)",  // Klarer für Hover-Zustände
      500: "rgba(249, 248, 244, 0.3)",  // Deutlichere Sichtbarkeit für aktive Elemente
      600: "rgba(249, 248, 244, 0.4)",  // Stärker für akzentuierte UI-Elemente
      700: "rgba(249, 248, 244, 0.6)",  // Mehr Sättigung für Overlay-Komponenten
      800: "rgba(249, 248, 244, 0.8)",  // Wenig Transparenz für dominante Elemente
      900: "rgba(249, 248, 244, 0.95)", // Fast opak für Hintergrundakzente
    },
    otherGray: {
      50: "#fafaf9",
      100: "#f2f1f2",
      200: "#e1dde3",
      300: "#c0b9c2",
      400: "#9a8f9a",
      500: "#7b6c76",
      600: "#635158",
      700: "#4a3c42",
      800: "#32292e",
      900: "#1e191c",
    },
    gray: {
      50: "#F9F9F9", // Ein sehr helles Grau mit einem Hauch von Wärme
      100: "#F1EFEA", // Angepasst für Buttons, um gut mit #fffaef zu harmonieren
      200: "#E6E2DD", // Ein wärmeres, leicht bräunliches Grau
      300: "#DAD6D1", // Mittleres Grau, sanft gewärmt
      400: "#B2ADA8", // Dunkleres Grau, noch wärmer
      500: "#8D8782", // Neutral-grau-braun, vielseitig
      600: "#706A65", // Tieferes Grau mit deutlicher Braunnote
      700: "#58534E", // Dunkelgrau, stark bräunlich getönt
      800: "#403C37", // Sehr dunkles Grau, nahe an Braun
      900: "#2B2824", // Fast schwarz, warm getönt
    },
    brown: {
      50: "#fcfaf7",
      100: "#d7ccc8",
      200: "#bcaaa4",
      300: "#a1887f",
      400: "#8d6e63",
      500: "#795548",
      600: "#6d4c41",
      700: "#5d4037",
      800: "#4e342e",
      900: "#3e2723",
    },
    deepOrange: {
      50: "#fef2e6",
      100: "#fde4cd",
      200: "#fdd7b3",
      300: "#fcc99a",
      400: "#fbbc81",
      500: "#faae68",
      600: "#f9a14f",
      700: "#f99335",
      800: "#f8861c",
      900: "#f77803",
    },
    orange: {
      50: "#fff4e6",
      100: "#ffe8cc",
      200: "#ffd8a8",
      300: "#ffc078",
      400: "#ffa94d",
      500: "#ff922b",
      600: "#fd7e14",
      700: "#f76707",
      800: "#e8590c",
      900: "#d9480f",
    },
    yellow: {
      50: "#fff9db",
      100: "#fff3bf",
      200: "#ffec99",
      300: "#ffe066",
      400: "#ffd43b",
      500: "#fcc419",
      600: "#fab005",
      700: "#f59f00",
      800: "#f08c00",
      900: "#e67700",
    },
    lime: {
      50: "#f4fce3",
      100: "#e9fac8",
      200: "#d8f5a2",
      300: "#c0eb75",
      400: "#a9e34b",
      500: "#94d82d",
      600: "#82c91e",
      700: "#74b816",
      800: "#66a80f",
      900: "#5c940d",
    },
    green: {
      50: "#ebfbee",
      100: "#d3f9d8",
      200: "#b2f2bb",
      300: "#8ce99a",
      400: "#69db7c",
      500: "#51cf66",
      600: "#40c057",
      700: "#37b24d",
      800: "#2f9e44",
      900: "#2b8a3e",
    },
    teal: {
      50: "#e6fcf5",
      100: "#c3fae8",
      200: "#96f2d7",
      300: "#63e6be",
      400: "#38d9a9",
      500: "#20c997",
      600: "#12b886",
      700: "#0ca678",
      800: "#099268",
      900: "#087f5b",
    },
    cyan: {
      50: "#e6fcf5",
      100: "#c3fae8",
      200: "#96f2d7",
      300: "#63e6be",
      400: "#38d9a9",
      500: "#20c997",
      600: "#12b886",
      700: "#0ca678",
      800: "#099268",
      900: "#087f5b",
    },
    blue: {
      50: "#e7f5ff",
      100: "#d0ebff",
      200: "#a5d8ff",
      300: "#74c0fc",
      400: "#4dabf7",
      500: "#339af0",
      600: "#228be6",
      700: "#1c7ed6",
      800: "#1971c2",
      900: "#1864ab",
    },
    indigo: {
      50: "#edf2ff",
      100: "#dbe4ff",
      200: "#bac8ff",
      300: "#91a7ff",
      400: "#748ffc",
      500: "#5c7cfa",
      600: "#4c6ef5",
      700: "#4263eb",
      800: "#3b5bdb",
      900: "#364fc7",
    },
    violet: {
      50: "#f3f0ff",
      100: "#e5dbff",
      200: "#d0bfff",
      300: "#b197fc",
      400: "#9775fa",
      500: "#845ef7",
      600: "#7950f2",
      700: "#7048e8",
      800: "#6741d9",
      900: "#5f3dc4",
    },
    pink: {
      50: "#fff0f6",
      100: "#ffdeeb",
      200: "#fcc2d7",
      300: "#faa2c1",
      400: "#f783ac",
      500: "#f06595",
      600: "#e64980",
      700: "#d6336c",
      800: "#c2255c",
      900: "#a61e4d",
    },
    red: {
      50: "#fff5f5",
      100: "#ffe3e3",
      200: "#ffc9c9",
      300: "#ffa8a8",
      400: "#ff8787",
      500: "#ff6b6b",
      600: "#fa5252",
      700: "#f03e3e",
      800: "#e03131",
      900: "#c92a2a",
    },
  },
});
export default figuyaTheme;

