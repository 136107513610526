import React from "react";
import { Flex, Image, Button, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "./header/figuya-logo-5.png";

export const Landingpage = () => {
  const bg = useColorModeValue("whiteAlpha.100", "blackAlpha.100");
  return (
    <Flex
      gap={4}
      direction="column"
      alignItems="center"
      justifyContent="center"
      bg="orange.600"
      minHeight="100vh"
      width="100%"
      id="language-landing"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        rounded="full"
        width={["80vw", "300px"]}
        height={["80vw", "300px"]}
        bg={bg}
      >
        <Image maxWidth="250px" src={logo} />
      </Flex>
      <Flex p={2} gap="2" direction={["column", "row"]} wrap="wrap">
        <Button colorScheme="whiteAlpha" to="/de" as={Link}>
          Weiter auf Deutsch
        </Button>
        <Button colorScheme="whiteAlpha" to="/en" as={Link}>
          Continue in English
        </Button>
      </Flex>
    </Flex>
  );
};
