import React from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShoppingFast } from "@fortawesome/pro-regular-svg-icons/faCartShoppingFast";
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons/faCartShopping";
import { faCartCircleCheck } from "@fortawesome/pro-regular-svg-icons/faCartCircleCheck";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Heading,
  Flex,
  FormLabel,
  FormControl,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { useUpdateCartLine, useFetchCart } from "../api";
import { useTranslation } from "react-i18next";
import * as CC from "vanilla-cookieconsent";

export const AddToCartButton = ({ product, size = "md", ...props }) => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const [qty, setQty] = React.useState("1");
  const cartQuery = useFetchCart({ refetchOnMount: false });
  const id = React.useId();
  const updateCartLine = useUpdateCartLine();
  const cartItem = cartQuery.data.cart_items.find(
    (item) => product.id === item.id
  );
  React.useEffect(() => {
    if (cartItem && cartQuery.isSuccess) setQty(cartItem.quantity.toString());
  }, [cartQuery.isFetching]);
  if (cartItem)
    return (
      <Popover isLazy>
        <PopoverTrigger>
          <Button
            {...props}
            size={size}
            colorScheme="orange"
            leftIcon={<FontAwesomeIcon icon={faCartCircleCheck} />}
          >
            {t("cart")}
          </Button>
        </PopoverTrigger>
        <PopoverContent zIndex="30">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Heading as="div" size="sm" noOfLines={1}>
              {product.title}
            </Heading>
          </PopoverHeader>
          <PopoverBody>
            <FormControl gap={2} wrap="wrap" as={Flex}>
              <FormLabel flexBasis="100%" htmlFor={`quantity-${id}`}>
                {t("qty")}
              </FormLabel>
              <NumberInput
                min="0"
                flex="2"
                onChange={setQty}
                id={`quantity-${id}`}
                value={qty}
                step={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Button
                isDisabled={qty === cartItem.quantity.toString()}
                isLoading={updateCartLine.isPending}
                colorScheme="blue"
                onClick={() => {
                  updateCartLine.mutate({
                    product_id: product.id,
                    quantity: qty,
                    action: "update",
                  })
                }}
                flexBasis="max-content"
              >
                {t("change")}
              </Button>
            </FormControl>
          </PopoverBody>
          <PopoverFooter as={Flex} justifyContent="flex-end">
            <Button
              to={`/${locale}/cart`}
              leftIcon={<FontAwesomeIcon icon={faCartShoppingFast} />}
              as={Link}
              colorScheme="orange"
            >
              {t("go-to-cart")}
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    );
  else
    return (
      <Button
        {...props}
        isDisabled={!product.orderable || cartQuery.isLoading}
        onClick={() => {
          if (CC.acceptedCategory('ads') && typeof window.fbq === 'function') {
            window.fbq('track', 'AddToCart', {
              content_ids: [product.id],
              content_type: 'product',
              value: product.gross_selling_price,
              currency: 'EUR'
            })
          }
          updateCartLine.mutate({
            product_id: product.id,
            quantity: 1,
            action: "create",
          })
        }}
        isLoading={updateCartLine.isPending}
        size={size}
        colorScheme="orange"
        leftIcon={<FontAwesomeIcon icon={faCartShopping} />}
      >
        {t("add-to-cart")}
      </Button>
    );
};
