import React from "react";
import {
  Flex,
  Heading,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { LoadingIndicator, Headline, NavLink, Description } from "../elements";
import { useFetchTaxon } from "../api";
import { DisplayProductQuery } from "../products/DisplayProductQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";

export const Taxon = () => {
  const { locale, taxonId } = useParams();
  const taxonQuery = useFetchTaxon(taxonId, { view: "show" });
  const location = useLocation();
  const { t } = useTranslation();
  if (taxonQuery.isLoading || taxonQuery.data.id === undefined)
    return <LoadingIndicator />;
  const taxon = taxonQuery.data;
  return (
    <>
      <Helmet>
        <title>{taxon.meta_title}</title>
        <meta name="description" content={taxon.meta_description} />
        {(taxon.alternates || []).map(({ href, lang }) => (
          <link
            key={lang}
            rel="alternate"
            href={href + location.search}
            hrefLang={lang}
          />
        ))}
      </Helmet>
      <Flex direction="column" overflow="clip" rounded="md">
        <Flex
          direction="column"
          justifyContent="center"
          bg="orange.600"
          color="orange.50"
          fontSize="lg"
          p={[2, 4]}>
          <Breadcrumb
            display="flex" sx={{
              webkitOverflowScrolling: "touch",
              '::-webkit-scrollbar': { display: 'none' }
            }} whiteSpace="nowrap" overflowY="hidden" width="100%" flexDirection="row" overflowX="auto"


            separator={<FontAwesomeIcon icon={faChevronRight} />}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={`/${locale}`}>
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={taxonQuery.data.taxonomy.href}>
                {taxonQuery.data.taxonomy.title}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={taxonQuery.data.href}>
                {taxonQuery.data.title}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading as="h1">{taxon.title}</Heading>
          <Flex wrap="wrap" direction="row" gap="2">
            <Box flex="1" size="sm" color="orange.200" fontWeight="semibold">
              {taxon.taxonomy.title}
            </Box>
            <NavLink
              to={"subscribe" + location.search}
              colorScheme="header"
              leftIcon={<FontAwesomeIcon icon={faHeart} />}
            >
              {t("subscribe-to-taxon", {
                taxonomy: taxonQuery.data.taxonomy.title,
              })}
            </NavLink>
          </Flex>
        </Flex>
        {taxon?.original_title && <Box>{taxon.original_title}</Box>}
        <DisplayProductQuery
          initialQuery={{ taxon_ids: [taxon.id.toString()] }}
        />
        <Box m="auto" maxWidth="750px">
          <Description>{taxon.description}</Description>
        </Box>
      </Flex>
    </>
  );
};
