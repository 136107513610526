import { Flex, Box, Image, Circle } from "@chakra-ui/react";
import shoppingChan from "../images/shopping-figuchan-48.png";
export const Divider = ({ children }) => (
  <Flex gap="2" alignItems="center" direction="row">
    <Box flex="1" borderColor="orange.500" borderBottomWidth="2px"></Box>
    {children ? (
      children
    ) : (
      <Circle borderColor="orange.500" borderWidth="2px" size="48px">
        <Image htmlWidth="96" htmlHeight="153" src={shoppingChan} />
      </Circle>
    )}
    <Box flex="1" borderColor="orange.500" borderBottomWidth="2px"></Box>
  </Flex>
);
