import { UnorderedList, ListItem, Box, Flex } from "@chakra-ui/react"
import { Headline } from "../elements"

export const HelpDeskWelcomeDe = () => {
  return <Flex direction="column" gap="2">
    <Headline title="Herzlich willkommen" subtitle="auf unserer FAQ-Startseite für unseren Sammler-Onlineshop!">
    </Headline>
    <Box fontFamily="heading" letterSpacing="wider">Hier findest du Antworten auf die häufigsten Fragen rund um unseren Shop und unsere Funktionen. Wir haben für dich die wichtigsten Fragen und Antworten zusammengefasst, damit du schnell und einfach die Informationen findest, die du benötigst.</Box>
    <Box>Auf dieser Seite findest du beispielsweise Antworten auf folgende Fragen:</Box>
    <UnorderedList>
      <ListItem>Wie kann ich meine Lieblingskategorien abonnieren?</ListItem>
      <ListItem>Wie reserviere ich Produkte für Vorbestellungen?</ListItem>
      <ListItem>Wie funktionieren die Merklisten und die Warteliste?</ListItem>
      <ListItem>Wie kann ich sicherstellen, dass meine Bestellung original ist?</ListItem>
      <ListItem>Wie kann ich mit dem Kundenservice in Kontakt treten?</ListItem>
      <ListItem>Natürlich ist diese Liste nicht abschließend und wir aktualisieren die Fragen und Antworten regelmäßig, um sicherzustellen, dass du stets die neuesten Informationen erhältst.</ListItem>
    </UnorderedList>
    <Box>
      Wenn du eine spezifische Frage hast, nutze gerne unsere Suchfunktion, um schnell die passenden Antworten zu finden. Alternativ kannst du auch unsere Kategorien nutzen, um gezielt nach Fragen zu suchen, die dich interessieren.
    </Box>
    <Box>
      Wir möchten dir ein reibungsloses Einkaufserlebnis bieten und dir dabei helfen, deine Sammlung zu erweitern. Solltest du dennoch Fragen oder Probleme haben, zögere nicht, uns zu kontaktieren. Wir stehen dir jederzeit gerne zur Verfügung.
    </Box>
    <Box>
      Wir wünschen dir viel Spaß beim Stöbern in unseren FAQ und hoffen, dass wir dir mit unseren Antworten weiterhelfen können!
    </Box>
  </Flex>
}
