import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarSharp } from "@fortawesome/pro-regular-svg-icons/faStarSharp";
import { Button } from "@chakra-ui/react";
import { useUiStore } from "../hooks";
import { useTranslation } from "react-i18next";

export const ReserveButton = ({ product, size = "md", ...props }) => {
  const ui = useUiStore();
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      variant="outline"
      isDisabled={!product.reservable}
      onClick={() => ui.toggleReservationModal(product)}
      size={size}
      colorScheme="purple"
      leftIcon={<FontAwesomeIcon icon={faStarSharp} />}
    >
      {t("reserve")}
    </Button>
  );
};
