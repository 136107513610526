import { Flex, Box, useColorModeValue } from "@chakra-ui/react";

export const DefinitionLineItem = ({
  label,
  children,
  colorScheme = "otherGray",
}) => {
  const borderColor = useColorModeValue(
    `${colorScheme}.200`,
    `${colorScheme}.500`
  );
  const textColor = useColorModeValue(
    `${colorScheme}.900`,
    `${colorScheme}.100`
  );

  return (
    <Flex gap={2} alignItems="center">
      <Box color={textColor} noOfLines={1} fontFamily="heading" letterSpacing="wider">
        {label}
      </Box>
      <Box flex="1" borderColor={borderColor} borderBottomWidth="2px" />
      <Box >{children}</Box>
    </Flex>
  );
};
