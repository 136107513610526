import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faSortAmountUp } from "@fortawesome/pro-regular-svg-icons/faSortAmountUp";
import { faSortAmountDown } from "@fortawesome/pro-regular-svg-icons/faSortAmountDown";
import {
  useBreakpointValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
  Box,
  useColorModeValue,
  CloseButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DefinitionItem } from "../elements";
import { faGrid } from "@fortawesome/pro-regular-svg-icons/faGrid";
import { faGrid5 } from "@fortawesome/pro-regular-svg-icons/faGrid5";
import { faTableList } from "@fortawesome/pro-regular-svg-icons/faTableList";
import { StockStateSelector } from "./StockStateSelector";
import { faCogs } from "@fortawesome/pro-regular-svg-icons";
import { useSearchUi } from "../hooks";

export const stockStateColors = {
  sold_out: "red",
  order_stop: "pink",
  preorder: "violet",
  backorder: "blue",
  stocked: "green",
};

export const orders = [
  "touched_at",
  "title",
  "gross_selling_price",
  "release_date",
  "reservation_count",
];

export const orderTexts = {
  touched_at: "Aktualität",
  title: "Alphabetisch",
  gross_selling_price: "Preis",
  release_date: "Releasedatum",
  reservation_count: "Beliebtheit",
};

export const stockStates = [
  "stocked",
  "backorder",
  "preorder",
  "sold_out",
  "order_stop",
];

const viewIcons = {
  grid: faGrid,
  "small-grid": faGrid5,
  list: faTableList,
  "small-list": faList,
};

export const FilterIndicator = ({ query, setQuery }) => {
  const { t } = useTranslation();
  const searchTermBg = useColorModeValue("yellow.100", "yellow.800");
  const searchUi = useSearchUi();
  const showAllButton = useBreakpointValue(
    {
      base: null,
      md: (
        <Button
          variant="link"
          size="sm"
          onClick={() =>
            setQuery({
              stock_state: [
                "stocked",
                "backorder",
                "preorder",
                "order_stop",
                "sold_out",
              ],
            })
          }
        >
          {t("show-all")}
        </Button>
      ),
    },
    { ssr: false }
  );
  const bg = useColorModeValue("white", "gray.800");
  return (
    <>
      <Flex
        p="1"
        zIndex="10"
        position="sticky"
        top="46px"
        bg={bg}
        wrap="wrap"
        borderBottomWidth="1px"
        alignItems="center"
        gap={[2, 4]}
      >
        {query.taxon_ids.filter(elem => elem).length > 0 && (
          <IconButton variant="link" size="sm" onClick={searchUi.toggleSidebar} icon={<FontAwesomeIcon icon={faCogs} />} />
        )}
        <Box
          as="button"
          aria-label="toggle direction"
          onClick={() =>
            setQuery({
              ...query,
              direction: query.direction === "desc" ? "asc" : "desc",
            })
          }
        >
          <FontAwesomeIcon
            icon={
              query.direction === "desc" ? faSortAmountDown : faSortAmountUp
            }
          />
        </Box>
        <DefinitionItem label={t("sorted-by")}>
          <Menu isLazy>
            <MenuButton>{t(query.order)}</MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => setQuery({ ...query, order: "popularity" })}
              >
                {t("popularity")}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setQuery({ ...query, order: "weekly_popularity" })
                }
              >
                {t("weekly_popularity")}
              </MenuItem>
              <MenuItem
                onClick={() => setQuery({ ...query, order: "touched_at" })}
              >
                {t("touched_at")}
              </MenuItem>
              <MenuItem
                onClick={() => setQuery({ ...query, order: "release_date" })}
              >
                {t("release_date")}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setQuery({ ...query, order: "gross_selling_price" })
                }
              >
                {t("gross_selling_price")}
              </MenuItem>
            </MenuList>
          </Menu>
        </DefinitionItem>
        <DefinitionItem label={t("stock-state")}>
          <StockStateSelector
            stockState={query.stock_state}
            setStockState={(stock_state) => setQuery({ ...query, stock_state })}
          />
        </DefinitionItem>
        <Box mx="auto">
          {query.term.length > 0 && (
            <DefinitionItem label={t("search-term")}>
              <Flex direction="row" gap="2" alignItems="center">
                <Box px="1" bg={searchTermBg} as="span" fontSize="sm">
                  {query.term}
                </Box>
                <CloseButton size="sm" onClick={() => setQuery({ term: "" })} />
              </Flex>
            </DefinitionItem>
          )}
        </Box>
        {showAllButton}
        <Menu isLazy>
          <MenuButton
            aria-label="open view mode menu"
            size="sm"
            variant="link"
            as={IconButton}
            icon={<FontAwesomeIcon icon={viewIcons[query.view]} />}
          ></MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => setQuery({ view: "grid" })}
              icon={<FontAwesomeIcon icon={faGrid} />}
            >
              {t("grid")}
            </MenuItem>
            <MenuItem
              onClick={() => setQuery({ view: "small-grid" })}
              icon={<FontAwesomeIcon icon={faGrid5} />}
            >
              {t("small-grid")}
            </MenuItem>
            <MenuItem
              onClick={() => setQuery({ view: "list" })}
              icon={<FontAwesomeIcon icon={faTableList} />}
            >
              {t("list")}
            </MenuItem>
            <MenuItem
              onClick={() => setQuery({ view: "small-list" })}
              icon={<FontAwesomeIcon icon={faList} />}
            >
              {t("small-list")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
};

export default FilterIndicator;
