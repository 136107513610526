import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import escapeRegExp from "lodash-es/escapeRegExp";

export const NavLink = React.forwardRef(
  (
    {
      children,
      leftIcon,
      rightIcon,
      to,
      onClick,
      isActive,
      isDisabled,
      isLoading,
      size = "md",
      colorScheme = "orange",
      variant = "",
      ...props
    },
    ref
  ) => {
    let color = useColorModeValue(`${colorScheme}.800`, `${colorScheme}.50`);
    let activeColor = useColorModeValue(
      `${colorScheme}.900`,
      `${colorScheme}.200`
    );
    let hoverColor = useColorModeValue(
      `${colorScheme}.500`,
      `${colorScheme}.400`
    );
    const location = useLocation();
    const active =
      isActive ||
      (to && location.pathname.match(new RegExp(`^${escapeRegExp(to)}`)));
    const as = props.as || (to === undefined ? "button" : Link);
    if (variant === "header") {
      color = "orange.50";
      activeColor = "white";
      hoverColor = "orange.200";
    }

    return (
      <Flex
        {...props}
        minWidth="26px"
        disabled={isDisabled || isLoading}
        ref={ref}
        alignItems="center"
        direction="row"
        opacity={isDisabled ? 0.6 : 1}
        color={active ? activeColor : color}
        onClick={onClick}
        _active={{
          color: activeColor,
        }}
        _hover={{
          color: hoverColor,
        }}
        as={as}
        to={to}
      >
        {leftIcon && <Box minWidth="24px">{leftIcon}</Box>}
        <Box
          fontSize={size}
          flex="1"
          textAlign="left"
          overflow="hidden"
          fontFamily="heading"
          letterSpacing="wider"
          noOfLines={1}
        >
          {children}
        </Box>
        {rightIcon && (
          <Flex direction="row" justifyContent="flex-end" minWidth="20px">
            {rightIcon}
          </Flex>
        )}
      </Flex>
    );
  }
);
