import React from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import {
  Heading,
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { Headline } from "../elements";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

export const ProductsLayout = () => {
  const { t } = useTranslation();
  const { locale } = useParams();
  return (
    <Flex direction="column">
      <Flex direction="column" overflow="clip" rounded="md">
        <Flex
          direction="column"
          justifyContent="center"
          bg="orange.600"
          color="orange.50"
          fontSize="lg"
          p={[2, 4]}>
          <Breadcrumb separator={<FontAwesomeIcon icon={faChevronRight} />}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={`/${locale}`}>
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={`/${locale}/products`}>
                {t("products")}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading as="h1">{t("products-title")}</Heading>
          <Box flex="1" size="sm" color="orange.200" fontWeight="semibold">
            {t("products-tagline")}
          </Box>
        </Flex>
        <Outlet />
      </Flex>
    </Flex>
  );
};
