import React from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Circle,
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import { ProductState } from "./ProductState";
import { ProductPrice } from "./ProductPrice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, ResponsiveTableContainer } from "../elements";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";
import dayjs from "dayjs";
import { Availability } from "./availability";
import { useSearchUi } from "../hooks";

function ProductAttributeTable({ product }) {
  const ui = useSearchUi();
  const { t } = useTranslation();
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.50");
  const {
    id,
    availability,
    weight,
    height,
    release_date,
    stock_state,
    reservation,
    preorder,
  } = product;
  return (
    <ResponsiveTableContainer>
      <Table>
        <Tbody>
          <Tr>
            <Th>{t("stock-state")}</Th>
            <Td>
              <Flex direction="row" gap="2" alignItems="center">
                <ProductState product={product} />
                <Availability availability={availability} />
              </Flex>
            </Td>
          </Tr>
          {preorder && (
            <Tr>
              <Th>{t("preordered")}</Th>
              <Td>
                <Flex alignItems="center" direction="row" gap="2">
                  <Circle
                    size="24px"
                    color="purple.700"
                    borderColor="purple.600"
                    borderWidth="2px"
                    bg={bg}
                  >
                    <FontAwesomeIcon size="xs" icon={faCheck} />
                  </Circle>
                  {dayjs(preorder.created_at).format("L")}
                </Flex>
              </Td>
            </Tr>
          )}
          {reservation && (
            <Tr>
              <Th>{t("reserved")}</Th>
              <Td>
                <Flex alignItems="center" direction="row" gap="2">
                  <Circle
                    size="24px"
                    color="green.700"
                    borderColor="green.600"
                    borderWidth="2px"
                    bg={bg}
                  >
                    <FontAwesomeIcon size="xs" icon={faCheck} />
                  </Circle>
                  {dayjs(reservation.created_at).format("L")}
                </Flex>
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>{t("item-no")}</Th>
            <Td>{id}</Td>
          </Tr>
          <Tr>
            <Th>{t("price")}</Th>
            <Td>
              <ProductPrice product={product} split={false}>
                <Link to="shipping">Zzgl. Versand</Link>
              </ProductPrice>
            </Td>
          </Tr>
          {release_date ? (
            <Tr>
              <Th>{release_date.label}</Th>
              <Td>
                <Box>
                  {release_date.value}{" "}
                  {release_date.outdated &&
                  ["order_stop", "preorder"].includes(stock_state) ? (
                    <Text color="blue.500">
                      Wir aktualisieren diesen Termin sobald uns neue
                      Informationen vorliegen.
                    </Text>
                  ) : null}
                </Box>
              </Td>
            </Tr>
          ) : null}
          {weight ? (
            <Tr>
              <Th>{t("weight")}</Th>
              <Td>{weight} g</Td>
            </Tr>
          ) : null}
          {height ? (
            <Tr>
              <Th>{t("size")}</Th>
              <Td>{height}</Td>
            </Tr>
          ) : null}
          {product.taxonomies.map((taxonomy) => (
            <Tr key={taxonomy.id}>
              <Th>
                <NavLink size="md" onClick={() => ui.pickTaxonomy(taxonomy.id)}>
                  {taxonomy.title}
                </NavLink>
              </Th>
              <Td>
                <Wrap spacing={2}>
                  {taxonomy.taxons.map((taxon) => (
                    <Tag
                      as={WrapItem}
                      key={taxon.id}
                      size="lg"
                      variant="solid"
                      colorScheme="orange"
                    >
                      <Box
                        as={Link}
                        mr="3"
                        color="orange.50"
                        aria-label={`${taxon.title} abonnieren`}
                        to={`subscribe/${taxon.slug}`}
                      >
                        <FontAwesomeIcon icon={faHeart} />
                      </Box>
                      <TagLabel noOfLines={1} as={Link} to={taxon.href}>
                        {taxon.title}
                      </TagLabel>
                    </Tag>
                  ))}
                </Wrap>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </ResponsiveTableContainer>
  );
}

export default ProductAttributeTable;
