import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Box,
  Heading,
} from "@chakra-ui/react";
import { useCreateTaxonSubscription, useFetchTaxon } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation, Trans } from "react-i18next";
import { TaxonSubscription } from "./TaxonSubscription";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faEnvelopeCircleCheck } from "@fortawesome/pro-regular-svg-icons/faEnvelopeCircleCheck";
import { NewProductsByDay } from "../products";

export const SubscribeToTaxon = () => {
  const { taxonId, subId } = useParams();
  const navigate = useNavigate();
  const taxonQuery = useFetchTaxon(subId || taxonId, { view: "subscription" });
  const hasSubscription = taxonQuery.data?.subscription;
  const location = useLocation();
  const { t } = useTranslation();
  if (taxonQuery.isFetching) return null;
  const taxon = taxonQuery.data;
  return (
    <Modal
      preserveScrollBarGap
      size="lg"
      onClose={() => navigate(".." + location.search)}
      isOpen
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" direction="row" gap="2">
          <FontAwesomeIcon
            size="2x"
            icon={hasSubscription ? faEnvelopeCircleCheck : faEnvelope}
          />
          <Box>
            {taxon.title} <Badge variant="solid">{taxon.taxonomy.title}</Badge>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="2">
            {taxon.subscription ? (
              <TaxonSubscription taxon={taxon} />
            ) : (
              <SubscribeScrollContainer taxon={taxon} />
            )}
            {taxon.products_by_day.length > 0 && (
              <Heading noOfLines={1} size="md">
                {t("last-months-news")}
                {" in "}
                <Box color="gray.500" as="small">
                  {taxon.title}
                </Box>
              </Heading>
            )}
            {taxon.products_by_day.map(({ date, count, images, is_new }) => (
              <NewProductsByDay
                images={images}
                sidebar={true}
                query={{
                  taxon_ids: [taxon.id],
                  stock_state: [
                    "stocked",
                    "preorder",
                    "backorder",
                    "order_stop",
                  ],
                }}
                day={date}
                count={count}
                isNew={is_new}
                key={date}
              />
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
const SubscribeScrollContainer = ({ taxon }) => {
  const createSubscription = useCreateTaxonSubscription();
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="2">
      <Box>
        <Trans
          i18nKey="subscribe-to-taxon-description"
          t={t}
          values={{ taxonomy: taxon.taxonomy.title }}
          components={{
            taxonomy: <Box as="span" letterSpacing="wider" color="gray.500" />,
          }}
        ></Trans>
      </Box>
      <Button
        onClick={() => createSubscription.mutate({ taxon_id: taxon.id })}
        isLoading={createSubscription.isPending}
        colorScheme="blue"
      >
        {t("subscribe-to-taxon", { taxonomy: taxon.taxonomy.title })}
      </Button>
    </Flex>
  );
};
