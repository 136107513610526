import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";

const DescriptionContainer = styled.div`
  font-family: "PT Sans", system-ui, sans-serif;
  b,
  strong {
    font-weight: bold;
  }
  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 300;
    line-height: 1.1;
  }

  h1 {
    font-size: clamp(1.3rem, 6vw, 1.8rem);
  }

  ul,
  ol {
    padding: 6px 12px;
  }

  h2 {
    font-size: clamp(1.2rem, 5vw, 1.6rem);
  }

  h3 {
    font-size: clamp(1.1rem, 4vw, 1.4rem);
  }
`;

export const Description = ({ children }) => (
  <Flex
    direction="column"
    gap="2"
    as={DescriptionContainer}
    mx="auto"
    id="description"
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
