import React from "react";
import {
  LinkBox,
  Heading,
  Box,
  Flex,
  Image,
  LinkOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams, Link } from "react-router-dom";
import {
  faRankingStar,
} from "@fortawesome/pro-regular-svg-icons";

export const TaxonIndexItem = ({ taxon, num, icon = faRankingStar }) => {
  const { locale } = useParams();
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200")
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")

  return <LinkBox _hover={{ bg: hoverBg }} as={Flex} px="2" py="16" bg={bg} alignItems="center" justifyContent="center" gap="4" direction="column" position="relative" key={taxon.id}>
    <Flex direction="row" gap="2">
      {taxon.products.map(img => <Link to={`/${locale}/products/${img.slug}`} key={img.slug}>
        <Image flex="1" rounded="md" htmlWidth="96" htmlHeight="96" src={img.src} />
      </Link>
      )}
    </Flex>
    <Flex alignItems="center" direction="column" gap="2">
      <Heading as="h3" size="md">
        <LinkOverlay as={Link} to={taxon.href + "?order=popularity"}>
          {taxon.title}
        </LinkOverlay>
      </Heading>
      <Box fontWeight="semibold" color="gray.500">{taxon.taxonomy.title}</Box>
    </Flex>
  </LinkBox>
}
