import React from "react";
import { Box, Checkbox, HStack, VisuallyHidden, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import xor from "lodash-es/xor";

export const stockStateColors = {
  sold_out: "red",
  order_stop: "pink",
  preorder: "violet",
  backorder: "blue",
  stocked: "green",
};

export const stockStates = [
  "stocked",
  "backorder",
  "preorder",
  "order_stop",
  "sold_out",
];

const StateIndicator = ({ state, isActive, stockState, setStockState }) => {
  const colorScheme = stockStateColors[state];
  const { t } = useTranslation();
  return (
    <>
      <VisuallyHidden id={`${state}-desc`}>{t(state)}</VisuallyHidden>
      <Tooltip
        bg={`${colorScheme}.500`}
        color={`${colorScheme}.50`}
        label={t(state)}
      >
        <Box>
          <Checkbox
            aria-labelledby={`${state}-desc`}
            size="lg"
            onChange={() => setStockState(xor(stockState, [state]))}
            isChecked={stockState.includes(state)}
            colorScheme={colorScheme}
          />
        </Box>
      </Tooltip>
    </>
  );
};

export const StockStateSelector = ({ stockState, setStockState }) => {
  return (
    <HStack my={1} spacing={1}>
      {stockStates.map((state) => (
        <StateIndicator
          setStockState={setStockState}
          stockState={stockState}
          key={state}
          state={state}
          isActive={stockState.includes(state)}
        />
      ))}
    </HStack>
  );
};
