import React from "react";
import { Badge, Button, Flex, Box } from "@chakra-ui/react";
import { NavLink } from "../elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useTranslation } from "react-i18next";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";
import { useDestroyTaxonSubscription } from "../api";
import { useParams } from "react-router-dom";

export const TaxonSubscription = ({ taxon }) => {
  const { locale } = useParams();
  const destroyTaxonSubscription = useDestroyTaxonSubscription();
  const { t } = useTranslation();
  return (
    <Flex minHeight="100%" gap={2} direction="column">
      <Flex
        alignItems="center"
        direction="row"
        gap="2"
        fontFamily="heading"
        letterSpacing="wider"
      >
        <Box>
          <Trans
            i18nKey="taxon-subscription-description"
            values={{ taxonomy: taxon.taxonomy.title }}
            components={{ taxonomy: <Badge variant="solid" /> }}
          />
        </Box>
      </Flex>
      <Button
        isLoading={destroyTaxonSubscription.isPending}
        onClick={() => destroyTaxonSubscription.mutate(taxon.subscription.id)}
        colorScheme="red"
      >
        {t("unsubscribe")}
      </Button>
      <NavLink
        leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
        to={taxon.href}
      >
        {taxon.title} ({t("show-all")})
      </NavLink>
      <NavLink
        to={`/${locale}/user/taxon-subscriptions`}
        leftIcon={<FontAwesomeIcon icon={faHeart} />}
      >
        {t("taxon-subscriptions")}
      </NavLink>
    </Flex>
  );
};
