import React from "react";
import {
  HStack,
  ButtonGroup,
  Badge,
  Grid,
  Image,
  Box,
  Flex,
  Heading,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { useFetchProductsByDate } from "../api";
import { LoadingIndicator } from "../elements/LoadingIndicator";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ProductListItem } from "../products/ProductListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid } from "@fortawesome/pro-regular-svg-icons/faGrid";
import { faListAlt } from "@fortawesome/pro-regular-svg-icons/faListAlt";
import { ProductGridItem } from "./ProductGridItem";
import { useUiStore } from "../hooks";

const ProductCollection = ({ view, products, sidebar }) => {
  const ui = useUiStore();
  const gap = 8; // useBreakpointValue({ base: 8, md: 16 }, { ssr: false });
  const perRow = React.useCallback(() => {
    return Math.floor((ui.width + gap) / (120 + gap));
  }, [ui.width, gap]);
  const cardWidth = React.useCallback(
    () => (ui.width - (perRow() - 1) * gap) / perRow(),
    [ui.width, perRow(), gap]
  );
  if (view === "small-grid")
    return (
      <Grid
        templateColumns="repeat(auto-fill,minmax(140px, 1fr))"
        gap={`${gap}px`}
      >
        {products.map((product) => (
          <ProductGridItem
            cardWidth={cardWidth()}
            product={product}
            key={product.id}
          />
        ))}
      </Grid>
    );
  else
    return (
      <Flex gap="1" direction="column">
        {products.map((product) => (
          <ProductListItem
            product={product}
            sidebar={sidebar}
            key={product.id}
          />
        ))}
      </Flex>
    );
};

export const NewProductsByDay = ({
  day,
  count,
  query,
  images = [],
  colorScheme = "green",
  isNew = false,
  sidebar = false,
}) => {
  const [details, setDetails] = React.useState(false);
  const [view, setView] = React.useState(sidebar ? "small-list" : "small-grid");
  const { t } = useTranslation();
  const hoverBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");
  const bg = useColorModeValue("white", "gray.700");
  const badgeBg = useColorModeValue("gray.600", "gray.200");
  const productQuery = useFetchProductsByDate(
    { date: day, ...query },
    { enabled: details }
  );
  return (
    <Flex direction="column" gap="2" position="relative">
      <Flex
        py="1"
        zIndex="2"
        position="sticky"
        top="0px"
        bg={bg}
        cursor="pointer"
        onClick={() => setDetails(!details)}
        _hover={{ bg: hoverBg }}
        direction="row"
        alignItems="center"
        gap="2"
      >
        <Heading size="sm" as="div">
          {dayjs(day).format("LL")}
        </Heading>
        {isNew && (
          <Badge variant="solid" colorScheme="green">
            {t("new")}
          </Badge>
        )}
        <Box borderBottomWidth="2px" minWidth="10px" flex="1" />
        <HStack spacing={-1}>
          {images.map((img) => (
            <Box overflow="hidden" key={img} rounded="full" borderWidth="2">
              <Image src={img} htmlWidth="32" htmlHeight="32" />
            </Box>
          ))}
          {count > images.length && (
            <Flex
              alignItems="center"
              justifyContent="center"
              color={bg}
              bg={badgeBg}
              rounded="full"
              width="36px"
              height="36px"
              variant="solid"
            >
              +{count - images.length}
            </Flex>
          )}
        </HStack>
      </Flex>
      {details && (
        <>
          {!sidebar && (
            <Flex justifyContent="flex-end">
              <ButtonGroup colorScheme="blue" isAttached size="sm">
                <IconButton
                  isActive={view === "small-grid"}
                  icon={<FontAwesomeIcon icon={faGrid} />}
                  onClick={() => setView("small-grid")}
                />
                <IconButton
                  isActive={view === "small-list"}
                  icon={<FontAwesomeIcon icon={faListAlt} />}
                  onClick={() => setView("small-list")}
                />
              </ButtonGroup>
            </Flex>
          )}
          {productQuery.isFetching ? (
            <Box position="relative" minHeight="60px">
              <LoadingIndicator />
            </Box>
          ) : (
            <ProductCollection
              view={view}
              products={productQuery.data.products}
              sidebar={sidebar}
            />
          )}
        </>
      )}
    </Flex>
  );
};
