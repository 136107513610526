import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CC from "vanilla-cookieconsent";

const enableReporting = () => {
  // console.log('enable crash reporting')
}
const disableReporting = () => {
  // console.log('disable crash reporting')
}


const initGTM = () => {
  console.log('initializing GTM and Pixel')
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }
  gtag('js', new Date());


  gtag('config', 'G-SQZS2R7KQQ');

}

const CookieConsent = ({ children }) => {
  const { locale } = useParams();
  const location = useLocation();
  React.useEffect(() => {
    CC.run({
      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {
          services: {
            ga: {
              label: "Google Analytics",
            }

          },
          autoClear: {
            cookies: [
              {
                name: /^_ga/,   // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid',   // string: exact cookie name
              }
            ]
          },
        },
        ads: {
          services: {
            fb: {
              label: "Facebook Pixel",
            }
          }
        }
      },
      onFirstConsent: ({ cookie }) => {
      },
      onConsent: ({ cookie }) => {
        if (CC.acceptedCategory('analytics')) {
          enableReporting()
        }
        if (CC.acceptedCategory('ads')) {
          initGTM()
        }

      },
      onChange: ({ changedCategories, changedServices }) => {
        if (CC.acceptedCategory('analytics')) {
          enableReporting()
        }
        else {
          disableReporting()
        }
        if (CC.acceptedCategory('ads')) {
          initGTM()
        }
        else {
          console.log('clearing cookies')
          CC.eraseCookies(['_gid', /^_ga/], '/', location.hostname);
        }

      },
      language: {
        default: locale,
        translations: {
          "de": {
            "consentModal": {
              "title": "Deine Privatsphäre ist uns wichtig",
              "description": "Um Dein Erlebnis zu verbessern, verwenden wir Cookies, um Deine Vorlieben zu speichern und personalisierte Inhalte bereitzustellen. Wähle aus, wie wir mit Cookies umgehen sollen.",
              "acceptAllBtn": "Alle Cookies akzeptieren",
              "acceptNecessaryBtn": "Nur notwendige",
              "showPreferencesBtn": "Einstellungen anpassen"
            },
            "preferencesModal": {
              "title": "Deine Cookie-Einstellungen anpassen",
              "acceptAllBtn": "Alle Cookies akzeptieren",
              "acceptNecessaryBtn": "Nur notwendige Cookies",
              "savePreferencesBtn": "Einstellungen speichern",
              "closeIconLabel": "Einstellungen schließen",
              "sections": [
                {
                  "title": "Was Du über Cookies wissen solltest",
                  "description": "Cookies helfen, Dein Browsing-Erlebnis zu verbessern, indem sie Deine Vorlieben speichern, Social Media-Funktionen ermöglichen, die Nutzung der Seite analysieren und Werbung personalisieren."
                },
                {
                  "title": "Notwendige Cookies",
                  "description": "Notwendige Cookies sorgen dafür, dass die Website richtig funktioniert. Sie sind erforderlich für die Grundfunktionalität der Seite und können nicht ausgeschaltet werden.",
                  "linkedCategory": "necessary"
                },
                {
                  "title": "Werbungs-Cookies",
                  "description": "Werbungs-Cookies werden verwendet, um Werbung an Deine Interessen anzupassen, sowohl innerhalb als auch außerhalb unserer Seite. Sie helfen auch, die Wirksamkeit von Werbeanzeigen zu messen.",
                  "linkedCategory": "ads"
                },
                {
                  "title": "Leistungs- & Analyse-Cookies",
                  "description": "Diese Cookies helfen uns, Interaktionen der Besucher durch anonym gesammelte Informationen zu verstehen. Das hilft uns, die Webseite für alle Nutzer zu verbessern.",
                  "linkedCategory": "analytics"
                },
                {
                  "title": "Mehr erfahren",
                  "description": `Für detailliertere Informationen über die von uns verwendeten Cookies, wie wir sie einsetzen und Deine Wahlmöglichkeiten besuche bitte unsere <a href=\"/${locale}/privacy\">Datenschutzrichtlinie</a>.`
                }
              ]
            }
          },
          "en": {
            "consentModal": {
              "title": "Your Privacy Matters",
              "description": "To enhance your experience, we use cookies to remember your preferences and provide personalized content. Choose how you'd like us to handle cookies.",
              "acceptAllBtn": "Accept All Cookies",
              "acceptNecessaryBtn": "Only Essential",
              "showPreferencesBtn": "Customize Settings"
            },
            "preferencesModal": {
              "title": "Customize Your Cookie Settings",
              "acceptAllBtn": "Accept All Cookies",
              "acceptNecessaryBtn": "Only Essential Cookies",
              "savePreferencesBtn": "Save Preferences",
              "closeIconLabel": "Close Settings",
              "sections": [
                {
                  "title": "Understanding Cookies",
                  "description": "Cookies help improve your browsing experience by remembering your preferences, enabling social media features, analyzing site usage, and personalizing ads."
                },
                {
                  "title": "Essential Cookies",
                  "description": "Essential cookies ensure the website functions properly. They are required for basic site functionality and cannot be switched off.",
                  "linkedCategory": "necessary"
                },
                {
                  "title": "Advertisement Cookies",
                  "description": "Advertisement cookies are used to tailor advertising to your interests, both within and beyond our site. They also help measure the effectiveness of ads.",
                  "linkedCategory": "ads"
                },
                {
                  "title": "Performance & Analytics",
                  "description": "These cookies help us understand visitor interactions by collecting information anonymously. This helps us improve the website for all users.",
                  "linkedCategory": "analytics"
                },
                {
                  "title": "Learn More",
                  "description": `For more detailed information about the cookies we use, how we use them, and your choices, please visit our <a href=\"/${locale}/privacy\">Privacy Policy</a>.`
                }
              ]
            }
          }
        }
      }
    })
  }, [])

  return <>
    <Helmet>
      {CC.acceptedCategory('ads') && <script id="gtm" async src="https://www.googletagmanager.com/gtag/js?id=G-SQZS2R7KQQ"></script>}

      {CC.acceptedCategory('ads') &&
        <script key="pixel" id="facebook-pixel">
          {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '149713283795436');
fbq('track', 'PageView');
`}
        </script>}
    </Helmet >
    {children}
  </>
}
export default CookieConsent;
