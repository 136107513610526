import { useFetchTaxonomy } from "../api/queries";
import { useSearchUi } from "../hooks";
import { MenuContainer } from "./MenuContainer";
import { TaxonItem } from "./SearchOverlay";
import { LoadingIndicator } from "../elements";
import { useTranslation } from "react-i18next";

export const SearchTaxonomy = () => {
  const ui = useSearchUi();
  const { t } = useTranslation();
  const taxonomyQuery = useFetchTaxonomy(
    ui.taxonomy,
    { term: ui.query.term },
    { enabled: ui.taxonomy !== null }
  );
  if (taxonomyQuery.isLoading) return <LoadingIndicator />;
  return (
    <MenuContainer
      title={t(ui.query.term === "" ? "popular-in" : "found-in", {
        taxon: taxonomyQuery.data.title,
      })}
    >
      {taxonomyQuery.data.taxons.map((taxon) => (
        <TaxonItem taxon={taxon} key={taxon.id} ui={ui} />
      ))}
    </MenuContainer>
  );
};
