import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
} from "@chakra-ui/react";

const colors = {
  "stocked": "green",
  "sold_out": "red",
  "backorder": "blue"
}

export const VolumeItem = ({ id, volume, stock_state }) => {
  const color = colors[stock_state];
  const { locale } = useParams();

  return <Box as={Link} to={`/${locale}/products/${id}`} px="1" fontSize="sm" fontWeight="bold" key={id} color={`${color}.50`} bg={`${color}.500`} borderColor={`${color}.100`} borderWidth="2px">{volume || '/'}</Box>
}
