import React from "react";
import { ProductCard } from "./ProductCard";
import { DisplayListItem } from "./DisplayListItem";
import { ProductListItem } from "./ProductListItem";
import { ProductGridItem } from "./ProductGridItem";
import {
  Heading,
  Grid,
  Flex,
  Box,
  useBreakpointValue,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useFetchInfiniteProducts } from "../api";
import { useUiStore, useProductSearchParams } from "../hooks";
import { FilterIndicator } from "./FilterIndicator";
import { NavLink, LoadingIndicator, LoadingSidebarIndicator } from "../elements";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-cool-inview";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { TaxonSidebar } from "../taxons";

const Page = ({
  gap,
  products,
  page,
  total_pages,
  view = "grid",
  isLast = false
}) => {
  const { t } = useTranslation();
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200")
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
  const borderColor = useColorModeValue("orange.600", "orange.200")
  return (<>
    <Box bg={bg} py="16" px={[2, 4]}>
      <Flex zIndex="4" alignItems="center">
        <Box flexGrow="1" borderBottomWidth="2px" borderColor={borderColor} />
        <Box
          flex="160px 0 0"
          textAlign="center"
          p={2}
          fontWeight="bold"
          fontFamily="heading"
          letterSpacing="wider"
        >
          {t("page-x-of-y", { page, total_pages })}
        </Box>
        <Box flexGrow="1" borderBottomWidth="2px" borderColor={borderColor} />
      </Flex>
      <Box overflow="auto">
        {view === "small-grid" && (
          <Grid
            rowGap={[8, 16]}
            columnGap={[4, 8]}
            overflow="clip"
            templateColumns="repeat(auto-fill,minmax(140px, 1fr))"
          >
            {products.map((product) => (
              <ProductGridItem
                product={product}
                key={product.id}
              />
            ))}
          </Grid>
        )}
        {view === "grid" && (
          <Grid
            rowGap={[8, 16]}
            columnGap={[4, 8]}
            overflow="clip"
            templateColumns={[
              "repeat(auto-fill,minmax(160px, 1fr))",
              "repeat(auto-fill,minmax(180px, 1fr))",
            ]}
          >
            {products.map((product) => (
              <ProductCard
                product={product}
                key={product.id}
              />
            ))}
          </Grid>
        )}
        {view === "small-list" && (
          <Flex direction="column" gap={`${gap}px`}>
            {products.map((product) => (
              <ProductListItem product={product} key={product.id} />
            ))}
          </Flex>
        )}
        {view === "list" && (
          <Flex direction="column" gap={`${gap}px`}>
            {products.map((product) => (
              <DisplayListItem product={product} key={product.id} />
            ))}
          </Flex>
        )}
      </Box>
    </Box >
    {
      isLast &&
      <Flex
        bg={bg}
        _hover={{ bg: hoverBg }}
        px={[2, 4]}
        py={[8, 16]}
        gap="2"
        direction="column"
        alignItems="center"
        cursor="pointer"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <Heading as="div" color="gray.500" size="sm">
          {t("you-are-finished")}
        </Heading>
        <NavLink leftIcon={<FontAwesomeIcon icon={faArrowUp} />}>
          {t("scroll-to-top")}
        </NavLink>
      </Flex>
    }
  </>
  );
};

const LoadingBar = ({ loading, page, total_pages }) => {
  const { t } = useTranslation();
  const loadingBar = {
    start: {
      width: 0,
    },
    end: {
      width: "100%",
      transition: {
        duration: 0.5,
        ease: "linear",
      },
    },
  };
  return (
    <AnimatePresence>
      {loading && (
        <>
          <Box flex="1">
            <Box
              as={motion.div}
              borderBottomWidth="2px"
              bg="orange.600"
              initial="start"
              animate="end"
              exit="exit"
              variants={loadingBar}
            />
          </Box>
          <Box
            flex="160px 0 0"
            p={2}
            fontWeight="bold"
            textAlign="center"
            fontFamily="heading"
            letterSpacing="wider"
          >
            {t("page-x-of-y", { page, total_pages })}
          </Box>
          <Box flex="1">
            <Box
              as={motion.div}
              bg="orange.600"
              initial="start"
              animate="end"
              borderBottomWidth="2px"
              exit="exit"
              variants={loadingBar}
            />
          </Box>
        </>
      )}
    </AnimatePresence>
  );
};

const LoadingPage = ({ productQuery, total_pages, page, height }) => {
  const [timerId, setTimerId] = React.useState(null);
  const { observe, inView } = useInView({
    onLeave: (e) => {
      clearTimeout(timerId);
      setTimerId(null);
    },
    onEnter: ({ unobserve, entry }) => {
      const tid = setTimeout(() => {
        productQuery.fetchNextPage();
        // unobserve();
      }, 550);
      setTimerId(tid);
    },
  });
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");
  return (
    <Box bg={bg} py="16" px="4">
      <Flex
        animate="height"
        position="relative"
        minHeight="60vh"
        height={`${height}px`}
        direction="column"
        ref={observe}
      >
        <Flex
          zIndex="4"
          alignItems="center"
          justifyContent="center"
          minHeight="40px"
        >
          <LoadingBar loading={inView} page={page} total_pages={total_pages} />
        </Flex>
        {productQuery.isFetching ? <Flex alignItems="center" justifyContent="center" flex="1">
          <FontAwesomeIcon size="2x" icon={faSpinner} spin />
        </Flex> :
          <Flex onClick={() => productQuery.fetchNextPage()} alignItems="center" justifyContent="center" flex="1">
            <Button variant="outline">Sofort laden</Button>
          </Flex>}
      </Flex>
    </Box>
  );
};

export const DisplayProductQuery = ({ initialQuery }) => {
  const scrollRef = React.useRef();

  const [query, setQuery] = useProductSearchParams(initialQuery);

  const ui = useUiStore();

  let baseWidth = useBreakpointValue({ base: 140, sm: 180 }, { ssr: false });
  if (query.view !== "grid") baseWidth = 120;

  const gap = useBreakpointValue({ base: 8, md: 16 }, { ssr: false });
  const perRow = useBreakpointValue({ base: 2, sm: 3, md: 4 }, { ssr: false });
  const { data, ...productQuery } = useFetchInfiniteProducts(
    {
      ...query,
      per: 24,
    },
    {
      enabled: true,
      // getNextPageParam: (lastPage, pages) => {
      //   if (lastPage && pages.length < lastPage.meta.total_pages)
      //     return pages.length;
      //   else return undefined;
      // },
    }
  );

  const total_pages = React.useMemo(
    () => (data.pages.length > 0 ? data.pages[0].meta.total_pages : 0),
    [data]
  );
  React.useEffect(() => {
    if (productQuery.isSuccess && !productQuery.isFetching && total_pages === 0)
      setQuery({
        stock_state: [
          "stocked",
          "backorder",
          "preorder",
          "order_stop",
          "sold_out",
        ],
      });
  }, [productQuery.isSuccess, productQuery.isFetching, total_pages]);
  if (productQuery.isLoading) return <LoadingIndicator />;
  return (
    <>
      <TaxonSidebar />
      <FilterIndicator query={query} setQuery={setQuery} />
      <Flex minHeight={`calc(100vh - 64px)`} direction="column" gap="16" ref={scrollRef}>
        {data.pages.map((page, index) => (
          <Page
            key={index}
            products={page.products}
            gap={gap}
            page={index + 1}
            total_pages={total_pages}
            view={query.view}
            isLast={data.pages.length > 0 && index + 1 === total_pages}
          />
        ))}
        {total_pages > 0 && productQuery.hasNextPage && (
          <LoadingPage
            key={data.pages.length}
            page={data.pages.length + 1}
            total_pages={total_pages}
            productQuery={productQuery}
          />
        )}
      </Flex>
    </>
  );
};
