import React from 'react';
import {
  Box, Heading, Flex, useColorModeValue,
} from '@chakra-ui/react';

export const Message = ({
  color = 'blue', body, header, children, ...props
}) => {
  const text = useColorModeValue('gray.100', 'gray.700');
  const bg = useColorModeValue(`${color}.600`, `${color}.200`);
  const border = useColorModeValue(`${color}.200`, `${color}.600`);
  return (
    <Flex direction="column" gap="2"
      px={[2, 4]}
      py={[4, 8]}
      bg={bg}
      color={text}
      {...props}
    >
      {header && (
        <Heading as="p" size="md">
          {header}
        </Heading>
      )}
      <Box fontSize="md">
        {body}
      </Box>
      {children && <Box p={2}>{children}</Box>}
    </Flex>
  );
}
