import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Circle,
  Image,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useUiStore } from "../hooks";
import { AcceptTerms, DefinitionItem, LoadingIndicator } from "../elements";
import { ProductPrice } from "./ProductPrice";
import { useTranslation } from "react-i18next";
import { useCreateReservation, useFetchProduct } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import dayjs from "dayjs";

export const NewReservation = () => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.50");
  const createReservation = useCreateReservation();
  const checkboxRef = React.useRef();
  const create = (e) => {
    e.preventDefault();
    createReservation.mutate({ product_id: ui.reservationCandidate.id });
  };
  const ui = useUiStore();
  const productQuery = useFetchProduct(ui.reservationCandidate?.slug, {
    enabled: ui.showReservationModal,
  });
  return (
    <Modal
      preserveScrollBarGap
      onClose={ui.toggleReservationModal}
      isOpen={ui.showReservationModal}
      initialFocusRef={checkboxRef}
    >
      <ModalOverlay />
      {ui.showReservationModal && (
        <form onSubmit={create}>
          <ModalContent minHeight="200px">
            {productQuery.isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <ModalHeader>
                  {t("new-reservation")}{" "}
                  <Box color="gray.500" as="small">
                    {ui.reservationCandidate.title}
                  </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody position="relative">
                  <Flex direction="column" gap={2}>
                    <Flex
                      wrap="wrap"
                      gap={2}
                      alignItems="center"
                      direction="row"
                    >
                      <Box flex="0 1 200px">
                        <Link
                          onClick={ui.toggleReservationModal}
                          to={ui.reservationCandidate.href}
                        >
                          <Image
                            htmlHeight="200"
                            htmlWidth="200"
                            rounded="md"
                            src={
                              ui.reservationCandidate.profile_picture.profile
                                .url
                            }
                          />
                        </Link>
                      </Box>
                      <Flex flex="2" direction="column" gap={2}>
                        <DefinitionItem label="Preis">
                          <ProductPrice
                            split={false}
                            product={productQuery.data}
                          />
                        </DefinitionItem>
                        <DefinitionItem
                          label={productQuery.data.release_date.label}
                        >
                          {productQuery.data.release_date.value}
                        </DefinitionItem>
                        {productQuery.data.reservation && (
                          <DefinitionItem label={t("reserved")}>
                            <Flex alignItems="center" direction="row" gap="2">
                              <Circle
                                size="24px"
                                color="green.700"
                                borderColor="green.600"
                                borderWidth="2px"
                                bg={bg}
                              >
                                <FontAwesomeIcon size="xs" icon={faCheck} />
                              </Circle>
                              {dayjs(
                                productQuery.data.reservation.created_at
                              ).format("L")}
                            </Flex>
                          </DefinitionItem>
                        )}
                      </Flex>
                    </Flex>
                    {!productQuery.data.reservation && (
                      <>
                        <Box
                          fontSize="lg"
                          fontFamily="heading"
                          fontWeight="500"
                          letterSpacing="wider"
                        >
                          {t("dont-miss-reservation")}
                        </Box>
                        <AcceptTerms link={`/${locale}/info/terms`} />
                      </>
                    )}
                  </Flex>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="ghost"
                    mr={3}
                    onClick={ui.toggleReservationModal}
                  >
                    {t("close")}
                  </Button>
                  {ui.signedIn &&
                    (productQuery.data.reservation ? (
                      <Button
                        onClick={ui.toggleReservationModal}
                        colorScheme="purple"
                        variant="ghost"
                        as={Link}
                        to={`/${locale}/user/reservations`}
                      >
                        {t("reservations")}
                      </Button>
                    ) : (
                      <Button
                        isLoading={createReservation.isPending}
                        type="submit"
                        colorScheme="purple"
                      >
                        {t("reserve-now")}
                      </Button>
                    ))}
                  {!ui.signedIn && (
                    <Button
                      as={Link}
                      onClick={ui.toggleReservationModal}
                      colorScheme="purple"
                      to={
                        `/${locale}/accounts/sign-in?` +
                        new URLSearchParams({ redirect: location.pathname })
                      }
                    >
                      {t("reserve-now")}
                    </Button>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </form>
      )}
    </Modal>
  );
};
