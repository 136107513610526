import { Badge, Flex, Box, Heading, useColorModeValue } from "@chakra-ui/react";
import { NavLink } from "../elements";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { useFetchQuestions } from "../api/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";

const QuestionItem = ({ question }) => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const hoverBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");

  return (
    <Flex
      _hover={{ bg: hoverBg }}
      as={Link}
      to={`/${locale}/help-desk/${question.id}`}
      alignItems="center"
      direction="row"
      gap="2"
    >
      <Box fontFamily="heading" letterSpacing="wider">
        {question.question}
      </Box>
      <Box borderBottomWidth="2px" flex="1" minWidth="10px" />
      <Badge>{t(question.topic)}</Badge>
    </Flex>
  );
};

export const HelpMenu = () => {
  const questionsQuery = useFetchQuestions();
  const { locale } = useParams();
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="2">
      <Flex direction="row" justifyContent="space-between">
        <NavLink
          leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          to={`/${locale}/help-desk`}
        >
          {t("back")}
        </NavLink>
      </Flex>
      <Heading size="md">{t("question-menu-title")}</Heading>
      {questionsQuery.data.questions.map((question) => (
        <QuestionItem question={question} key={question.id} />
      ))}
    </Flex>
  );
};
