import styled from "@emotion/styled"

export const LoadingSidebarIndicator = styled.div`
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

display: flex;
align-items: center;
justify-content: center;
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;

background: linear-gradient(-45deg, var(--chakra-colors-whiteAlpha-100), var(--chakra-colors-blackAlpha-100), var(--chakra-colors-whiteAlpha-500), var(--chakra-colors-blackAlpha-500));
background-size: 400% 400%;
animation: gradient 1.0s ease infinite;
`
