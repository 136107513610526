import React from "react";
import { Link } from "react-router-dom";
import {
  Image,
  Heading,
  LinkBox,
  LinkOverlay,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  ButtonGroup,
} from "@chakra-ui/react";
import { Availability } from "./availability";
import { ProductState } from "./ProductState";
import { ProductPrice } from "./ProductPrice";
import ImagePreview from "./image-preview";
import { ProductActionMenu } from "./ProductActionMenu";
import { AddToCartButton } from "./AddToCartButton";
import { useHover } from "../hooks";
import { Blurhash } from "react-blurhash";
import { ReserveButton } from "./ReserveButton";

export const ProductHorizontalCard = ({ children, product }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const [ref, isHovered] = useHover();
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.50");
  const width = useBreakpointValue({ base: 125 }, { ssr: false });
  const noOfLines = useBreakpointValue({ base: 2 }, { ssr: false });
  const buttonSize = useBreakpointValue(
    { base: "sm", md: "md" },
    { ssr: false }
  );
  return (
    <LinkBox
      bg={bg}
      p="2"
      borderWidth="2px"
      ref={ref}
      as={Flex}
      gap="2"
      flex="1"
      minWidth={`${width + 190}px`}
      direction="row"
      title={product.title}
    >
      <Flex
        direction="column"
        position="relative"
        flexBasis={`${width}px`}
        flexGrow="0"
        flexShrink="0"
      >
        <ImagePreview
          product={product}
          images={product.images}
          isHovered={isHovered}
        />
        <Availability
          position="absolute"
          roundedTop={0}
          right={0}
          roundedRight={0}
          {...product.availability}
        />
        {product.blurhash && (
          <Blurhash
            hash={product.blurhash}
            width={width}
            height={width}
            style={{ display: isImageLoaded ? "none" : "block" }}
          />
        )}
        <Image
          onLoad={() => setImageLoaded(true)}
          display={isImageLoaded || !product.blurhash ? "block" : "none"}
          htmlHeight="160"
          htmlWidth="160"
          src={product.profile_picture.preview.url}
          srcSet={
            product.profile_picture.preview_webp &&
            product.profile_picture.profile_webp &&
            `${product.profile_picture.preview_webp.url}, ${product.profile_picture.profile_webp.url} 2x`
          }
          alt={product.title}
        />
        <ProductState
          position="absolute"
          roundedLeft={0}
          left={0}
          bottom={0}
          product={product}
        />
        {children}
      </Flex>
      <Flex flex="1" direction="column" gap={2}>
        <Heading mb="auto" size="sm" as="p">
          <LinkOverlay noOfLines={noOfLines} as={Link} to={product.href}>
            {product.title}
          </LinkOverlay>
        </Heading>
        <ProductPrice split={false} product={product} />
        <ButtonGroup isAttached>
          {["preorder", "order_stop"].includes(product.stock_state) ? (
            <ReserveButton size={buttonSize} product={product} />
          ) : (
            <AddToCartButton size={buttonSize} product={product} />
          )}
          <ProductActionMenu size={buttonSize} product={product} />
        </ButtonGroup>
      </Flex>
    </LinkBox>
  );
};
