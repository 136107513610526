import {
  CloseButton,
  Heading,
  Box,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useSearchUi } from "../hooks";

export const MenuContainer = ({ children, title }) => {
  const ui = useSearchUi();
  const bg = useColorModeValue("orange.50", "gray.700");
  return (
    <Box
      mt="2"
      as={motion.div}
      initial={{ scale: 0.1, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.1, opacity: 0 }}
      overflow="auto"
      rounded="sm"
      p={[2, 4]}
      bg={bg}
      position="absolute"
      top="0"
      left="0"
      right="0"
    >
      <Box maxHeight="calc(100vh - 100px)" overflow="auto">
        <Flex
          borderBottomWidth="2px"
          borderColor="orange.600"
          alignItems="center"
          direction="row"
          gap="2"
        >
          <Heading flex="1" as="div" size="md">
            {title}
          </Heading>
          <CloseButton onClick={ui.closeSearch} />
        </Flex>
        {children}
      </Box>
    </Box>
  );
};
