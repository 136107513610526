import React from "react";
import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { TaxonSidebar } from "../taxons";
import { ProductSidebar } from "../products";
import { Outlet, Route, Routes } from "react-router-dom";
import { ProductSidebarMenu } from "../products/SidebarMenu";
import { HelpMenu } from "../help-desk";
import { Taxonomies } from "../taxonomies";
import { LoadingSidebarIndicator } from "../elements";
const UserSidebar = React.lazy(() => import("../users/UserSidebar"));
const Onboarding = React.lazy(() => import("../accounts/Onboarding"));
const CartSidebar = React.lazy(() => import("../cart/CartSidebar"));
const MangaSidebar = React.lazy(() => import("../taxonomies/MangaSidebar"));
const AddProductsToWatchlist = React.lazy(() =>
  import("../watch-lists/AddProductsToWatchlist")
);

const SuperWideLayout = () => {
  const contentBg = useColorModeValue("white", "gray.800");
  return (
    <Flex
      bg={contentBg}
      overflow="clip"
      minHeight="100%"
      position="relative"
      p={[2, 4]}
      width={["100%", "100%", "400px"]}
      mb="-100px"
      direction="column"
      bottom="0"
    >
      <Box flexGrow={1} />
      <Box position="sticky" bottom="0" minHeight="calc(100vh - 64px)">
        <Outlet />
      </Box>
    </Flex>
  );
};

const WideLayout = () => {
  const contentBg = useColorModeValue("white", "gray.800");
  return (
    <Flex
      bg={contentBg}
      overflow="clip"
      minHeight="100%"
      position="relative"
      p={[2, 4]}
      width={["100%", "100%", "320px", "400px"]}
      mb="-100px"
      direction="column"
      bottom="0"
    >
      <Box flexGrow={1} />
      <Box position="sticky" bottom="0" minHeight="calc(100vh - 64px)">
        <Outlet />
      </Box>
    </Flex>
  );
};

const SidebarRoutes = () => {
  return (
    <Box mb={["100px", "100px", 0]}>
      <Routes>
        <Route path="products/*" element={null} />
        <Route path="taxons/*" element={null} />
        <Route path="search" element={null} />
        <Route index element={null} />
        <Route element={<WideLayout />}>
          <Route path="cart" element={<CartSidebar />} />
          <Route path="taxonomies/:taxonomyId">
            <Route index element={<Taxonomies />} />
            <Route path=":taxonId" element={<MangaSidebar />} />
          </Route>
          <Route path="products/*" element={null} />
          <Route path="user/*">
            <Route path="reservations/statistics" element={<UserSidebar />} />
            <Route path="reservations/mature" element={<UserSidebar />} />
            <Route
              path="watch-lists/:watchlistId/add"
              element={<AddProductsToWatchlist />}
            />
            <Route
              path="taxon-subscriptions/recent-updates"
              element={<UserSidebar />}
            />
            <Route path="*" element={<UserSidebar />} />
          </Route>
        </Route>
        <Route element={<SuperWideLayout />}>
          <Route path="help-desk/*" element={<HelpMenu />} />
          <Route path="accounts">
            <Route path="*" element={<Onboarding />} />
          </Route>
        </Route>
      </Routes>
    </Box>
  );
};

export const Sidebar = () => {
  return (
    <React.Suspense fallback={<LoadingSidebarIndicator />}>
      <SidebarRoutes />
    </React.Suspense>
  );
};
