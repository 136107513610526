import React from "react";
import { Link } from "react-router-dom";
import {
  Image,
  Heading,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Box,
} from "@chakra-ui/react";
import { ProductState } from "./ProductState";
import { ProductPrice } from "./ProductPrice";
import { ProductActionMenu } from "./ProductActionMenu";

export const NarrowListItem = ({ isActive, product }) => {
  return (
    <LinkBox
      gap={2}
      opacity={isActive ? 0.5 : 1}
      display="flex"
      flexDirection="row"
      title={product.title}
      as="article"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        htmlWidth="96"
        htmlHeight="96"
        shadow="inner"
        rounded="lg"
        src={product.profile_picture.thumb.url}
        srcSet={
          product.profile_picture.thumb_webp &&
          product.profile_picture.preview_webp &&
          product.profile_picture.small_profile_webp &&
          `${product.profile_picture.thumb_webp.url} 160w`
        }
        alt={product.title}
      />
      <Box flexGrow={1}>
        <ProductState product={product} />
        <Heading size="sm" as="p" noOfLines={1}>
          <LinkOverlay as={Link} to={product.href}>
            {product.title}
          </LinkOverlay>
        </Heading>
        <ProductPrice split={false} product={product} />
      </Box>
      <ProductActionMenu product={product} />
    </LinkBox>
  );
};
