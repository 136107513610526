import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faPalette } from "@fortawesome/pro-regular-svg-icons/faPalette";
import { faFolderUser } from "@fortawesome/pro-regular-svg-icons/faFolderUser";
import { faFilm } from "@fortawesome/pro-regular-svg-icons/faFilm";
import { faBooks } from "@fortawesome/pro-regular-svg-icons/faBooks";
import { faBook } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faCopyright } from "@fortawesome/pro-regular-svg-icons/faCopyright";
import { faRecycle } from "@fortawesome/pro-regular-svg-icons/faRecycle";
import { faIndustryWindows } from "@fortawesome/pro-regular-svg-icons/faIndustryWindows";
import { faRuler } from "@fortawesome/pro-regular-svg-icons/faRuler";

const taxonomyIcons = {
  11: faFolderUser,
  6: faPalette,
  2: faRecycle,
  4: faFilm,
  5: faFolder,
  18: faBooks,
  20: faBook,
  19: faCopyright,
  3: faIndustryWindows,
  1: faRuler,
};

export const TaxonomyIcon = ({ id, size = "lg" }) => (
  <FontAwesomeIcon icon={taxonomyIcons[id] || faFolder} size={size} />
);
