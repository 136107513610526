import React from "react";
import {
  Flex,
  SimpleGrid,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Box,
  useOutsideClick,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useUiStore } from "../hooks";
import { Link, useParams, useLocation } from "react-router-dom";
import { CartSummary } from "./CartSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShoppingFast } from "@fortawesome/pro-regular-svg-icons/faCartShoppingFast";

export const CartPopover = ({ children, cart = {} }) => {
  const { t } = useTranslation();
  const { cart_items = [] } = cart;
  const popoverRef = React.useRef();
  const ui = useUiStore();
  const { pathname } = useLocation();
  const { locale } = useParams();
  if (pathname === `/${locale}/cart`) return children;
  return (
    <Popover isLazy trigger="hover" openDelay={500} placement="bottom">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        my="2"
        rounded="0"
        shadow="md"
        zIndex="200"
        ref={popoverRef}
      >
        <PopoverHeader fontWeight="bold" border="0">
          {t("shopping-cart")}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex gap="2" direction="column">
            <SimpleGrid mb={4} spacing="1" columns={[4, 5]}>
              {cart_items.map((item) => (
                <Box
                  _hover={{ borderColor: "pink.500" }}
                  as={Link}
                  to={item.product.href}
                  overflow="hidden"
                  rounded="sm"
                  borderWidth="2px"
                  borderColor="pink.50"
                  key={item.id}
                >
                  <Image
                    width="100%"
                    htmlWidth="48"
                    htmlHeight="48"
                    src={item.product.profile_picture.cart.url}
                  />{" "}
                </Box>
              ))}
            </SimpleGrid>
            <CartSummary cart={cart} />
          </Flex>
        </PopoverBody>
        <PopoverFooter>
          <Button
            leftIcon={<FontAwesomeIcon icon={faCartShoppingFast} />}
            onClick={ui.disableCartPopover}
            as={Link}
            to="cart"
            colorScheme="orange"
          >
            {t("shopping-cart")}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
