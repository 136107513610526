import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  MenuDivider,
  Heading,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons/faEllipsisV";
import { faCartShoppingFast } from "@fortawesome/pro-regular-svg-icons/faCartShoppingFast";
import { faCartPlus } from "@fortawesome/pro-regular-svg-icons/faCartPlus";
import { faPlusHexagon } from "@fortawesome/pro-regular-svg-icons/faPlusHexagon";
import { faListAlt } from "@fortawesome/pro-regular-svg-icons/faListAlt";
import { faStarSharp } from "@fortawesome/pro-regular-svg-icons/faStarSharp";
import { useUiStore } from "../hooks";
import { useUpdateCartLine, useFetchCart } from "../api";
import { useTranslation } from "react-i18next";
import { ProductPrice } from "./ProductPrice";

export const ProductActionMenu = ({
  product,
  colorScheme = "orange",
  size = "sm",
}) => {
  const { locale } = useParams();
  const addToCart = useUpdateCartLine();
  const ui = useUiStore();
  const { t } = useTranslation();
  const cartQuery = useFetchCart({ refetchOnMount: false });
  if (cartQuery.isLoading)
    return (
      <IconButton
        size={size}
        colorScheme={colorScheme}
        isDisabled
        icon={<FontAwesomeIcon icon={faEllipsisV} />}
      />
    );
  const cartItem = cartQuery.data.cart_items.find(
    (item) => product.id === item.id
  );
  return (
    <Menu isLazy>
      <MenuButton
        aria-label="open product menu"
        as={IconButton}
        colorScheme={colorScheme}
        size={size}
        icon={<FontAwesomeIcon icon={faEllipsisV} />}
      />
      <MenuList maxWidth="300px" zIndex="5">
        <MenuItem
          d="flex"
          as={Link}
          to={product.href}
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          gap="2"
        >
          <Box flex="1">
            <Heading as="div" noOfLines={1} size="sm">
              {product.title}
            </Heading>
            <ProductPrice product={product} split={false} />
          </Box>
        </MenuItem>
        <MenuDivider />
        {cartItem ? (
          <MenuItem as={Link} to={`/${locale}/cart`}>
            <Box
              color="orange.500"
              as={FontAwesomeIcon}
              icon={faCartShoppingFast}
              mr={2}
            />
            {t("go-to-cart")}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() =>
              addToCart.mutate({ product_id: product.id, quantity: 1 })
            }
            isDisabled={!product.orderable}
          >
            <Box
              color="orange.500"
              as={FontAwesomeIcon}
              icon={faCartPlus}
              mr={2}
            />
            {t("add-to-cart")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => ui.toggleReservationModal(product)}
          isDisabled={!product.reservable}
        >
          <Box
            color="purple.500"
            as={FontAwesomeIcon}
            icon={faStarSharp}
            mr={2}
          />
          {t("reserve")}
        </MenuItem>
        <MenuItem
          onClick={() => ui.toggleWaitlistModal(product)}
          isDisabled={!product.waitlistable}
        >
          <Box
            color="blue.500"
            as={FontAwesomeIcon}
            icon={faPlusHexagon}
            mr={2}
          />
          {t("join-waitlist")}
        </MenuItem>
        <MenuItem onClick={() => ui.toggleWatchlistModal(product)}>
          <Box color="gray.500" as={FontAwesomeIcon} icon={faListAlt} mr={2} />
          {t("add-to-watchlist")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
