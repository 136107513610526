import { Box, Heading } from "@chakra-ui/react";

export const EmptyPlaceholder = ({ title, children, bg = "inherit" }) => {
  return (
    <Box>
      <Heading size="md" as="div">
        {title}
      </Heading>
      {children}
    </Box>
  );
};
