import React from "react";
import {
  SimpleGrid,
  Portal,
  Grid,
  Heading,
  Flex,
  Box,
  useColorModeValue,
  useDisclosure,
  CloseButton,
} from "@chakra-ui/react";
import { NavLink } from "../elements";
import { useTranslation } from "react-i18next";
import { Link, useParams, useLocation } from "react-router-dom";
import { useFetchHome } from "../api/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { ProductListItem } from "../products/ProductListItem";
import { AnimatePresence, motion } from "framer-motion";

const TaxonItem = ({ taxon }) => {
  const { locale } = useParams();
  return (
    <Flex
      py="1"
      direction="row"
      gap="2"
      as={Link}
      to={`/${locale}/taxonomies/herkunft/${taxon.slug}`}
    >
      <Box
        fontWeight="bold"
        fontFamily="heading"
        letterSpacing="wider"
        fontSize="md"
      >
        {taxon.title}
      </Box>
    </Flex>
  );
};

export const MangaMenu = ({ size, containerRef }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const location = useLocation();
  const { t } = useTranslation();
  React.useEffect(() => onClose, [location.pathname, location.search]);
  const homeQuery = useFetchHome("manga_menu", { isEnabled: isOpen });
  const menuBg = useColorModeValue("white", "black");
  return (
    <>
      <Flex direction="column">
        <NavLink
          aria-label={`Browse Manga`}
          rightIcon={<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />}
          isActive={isOpen}
          onClick={onToggle}
          size={size}
        >
          Manga
        </NavLink>
        <Portal containerRef={containerRef}>
          <AnimatePresence>
            {isOpen && homeQuery.isSuccess && (
              <>
                <Box
                  onClick={onClose}
                  position="fixed"
                  left="0"
                  right="0"
                  bottom="0"
                  top="0"
                ></Box>
                <Box
                  borderWidth="2px"
                  zIndex="202"
                  bg={menuBg}
                  overflow="auto"
                  top={[2, 4]}
                  left={[2, 4]}
                  right={[2, 4]}
                  p={[2, 4]}
                  position="absolute"
                  as={motion.div}
                  initial={{
                    opacity: 0,
                    transform: 'translateY(200px)'
                  }}
                  exit={{
                    opacity: 0,
                    transform: 'translateY(-200px)'
                  }}
                  animate={{
                    opacity: 1,
                    transform: 'translateY(0)'
                  }}
                >
                  <Flex
                    bg={menuBg}
                    zIndex="1"
                    position="sticky"
                    top="0"
                    mb={[2, 4]}
                    borderBottomWidth="2px"
                    borderColor="orange.600"
                    alignItems="center"
                    direction="row"
                    gap="2"
                  >
                    <Heading p={2} flex="1" as="div" size="lg" noOfLines={1}>
                      Manga
                    </Heading>
                    <CloseButton onClick={onClose} />
                  </Flex>
                  <SimpleGrid gap={[2, 4]} columns={[1, 1, 1, 3]}>
                    <Box>
                      <Heading p={2} borderBottomWidth="2px" size="md">
                        Genre
                      </Heading>
                      <Box p={2}>
                        {homeQuery.data.genre.map((taxon) => (
                          <TaxonItem key={taxon.id} taxon={taxon} />
                        ))}
                      </Box>
                    </Box>
                    <Box>
                      <Heading p={2} borderBottomWidth="2px" size="md">
                        {t("publisher")}
                      </Heading>
                      <Box p={2}>
                        {homeQuery.data.publisher.map((taxon) => (
                          <TaxonItem key={taxon.id} taxon={taxon} />
                        ))}
                      </Box>
                    </Box>
                    <Box>
                      <Heading p={2} borderBottomWidth="2px" size="md">
                        Manga Highlights
                      </Heading>
                      <Box p={2}>
                        {homeQuery.data.popular.map((product) => (
                          <ProductListItem key={product.id} product={product} />
                        ))}
                      </Box>
                    </Box>
                  </SimpleGrid>
                </Box>
              </>
            )}
          </AnimatePresence>
        </Portal>
      </Flex>
    </>
  );
};
