import React from "react";
import { Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const colors = {
  low: "orange",
  last: "red",
};

export const Availability = ({ availability, ...props }) => {
  const { t } = useTranslation();
  if (["low", "last"].includes(availability))
    return (
      <Badge {...props} variant="solid" colorScheme={colors[availability]}>
        {t(availability)}
      </Badge>
    );
  return null;
};
