import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import {
  Box,
  Flex,
  Circle,
  Image,
  CloseButton,
  useColorModeValue,
} from "@chakra-ui/react";

function ImageModal({ image, nextIndex, prevIndex, setCurrent }) {
  const ref = React.useRef();
  const bg = useColorModeValue("blackAlpha.800", "whiteAlpha.800");
  const buttonBg = useColorModeValue("blackAlpha.800", "whiteAlpha.800");
  const color = useColorModeValue("white", "black");
  const onClose = () => {
    setCurrent(null);
  };
  React.useEffect(() => {
    if (image) ref.current.focus({ preventScroll: true });
  }, [image]);
  return (
    <Box
      onKeyDown={(e) => {
        if (e.key.match(/\d/)) {
          setCurrent(e.key);
        } else if (nextIndex !== null && e.key === "ArrowRight") {
          setCurrent(nextIndex);
        } else if (prevIndex !== null && e.key === "ArrowLeft") {
          setCurrent(prevIndex);
        }
      }}
      tabIndex="0"
      ref={ref}
    >
      {image && (
        <Flex
          p={4}
          zIndex="500"
          color={color}
          onClick={onClose}
          alignItems="center"
          justifyContent="center"
          position="fixed"
          left={0}
          top={0}
          right={0}
          bottom={0}
          bg={bg}
        >
          {prevIndex !== null && (
            <Circle
              bg={buttonBg}
              onClick={(e) => {
                e.stopPropagation();
                setCurrent(prevIndex);
              }}
              cursor="pointer"
              shadow="md"
              position="absolute"
              top="auto"
              left={4}
              size="40px"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Circle>
          )}
          <Image
            maxWidth="100%"
            maxHeight="100%"
            onClick={(e) => e.stopPropagation()}
            alt={image.alt}
            src={image.file.url}
          // srcSet={image.file.full_double_webp.url}
          />
          {nextIndex !== null && (
            <Circle
              bg={buttonBg}
              shadow="md"
              onClick={(e) => {
                e.stopPropagation();
                setCurrent(nextIndex);
              }}
              cursor="pointer"
              position="absolute"
              top="auto"
              right={4}
              size="40px"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Circle>
          )}
          <CloseButton
            onClick={onClose}
            position="absolute"
            top="2"
            right="2"
          />
        </Flex>
      )}
    </Box>
  );
}

export default ImageModal;
