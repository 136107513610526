import { Flex, Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";

const LoadingAnimation = styled.div`
  @keyframes stretch {
    0% {
      transform: scale(0.3);
      background-color: #fcc419;
      border-radius: 100%;
    }
    50% {
      background-color: #fa5252;
    }
    100% {
      transform: scale(1.5);
      background-color: #fd7e14;
    }
  }
  height: 250px;
  width: 250px;
  margin: 0 auto;
  background-color: red;
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
`;

export const LoadingIndicator = () => (
  <Flex
    zIndex="100"
    backdropFilter="blur(5px)"
    position="absolute"
    top="0"
    bottom="0"
    left="0"
    right="0"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner
      color="orange.600"
      emptyColor="orange.50"
      size="xl"
      thickness="4px"
    />
  </Flex>
);
