import React from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Badge,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  Heading,
  Checkbox,
  Flex,
  CheckboxGroup,
  Tooltip,
  useColorModeValue,
  CloseButton,
} from "@chakra-ui/react";
import { LoadingSidebarIndicator } from "../elements";
import { useFetchTaxon, useFetchSimilarTaxons } from "../api";
import { useProductSearchParams } from "../hooks/useProductSearchParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { useSearchUi } from "../hooks";
import { useTranslation } from "react-i18next";
import {
  useQueryParams,
  ArrayParam,
} from "use-query-params";

export const TaxonSidebar = ({ }) => {
  const { taxonId } = useParams();
  const [query, _setQuery] = useQueryParams({ taxon_ids: ArrayParam })
  const shadow = useColorModeValue("lg", "dark-lg")
  const bg = useColorModeValue("white", "gray.700")
  const { t } = useTranslation()
  const searchUi = useSearchUi();
  const taxonQuery = useFetchTaxon(
    taxonId,
    { view: "sidebar", taxon_ids: query.taxon_ids },
    { enabled: searchUi.showSidebar }
  );
  const taxon = taxonQuery.data;
  return searchUi.showSidebar ?
    <Box
      shadow={shadow}
      bg={bg}
      zIndex="1000"
      position="fixed" top="0" bottom="0" left="0" width="320px"
    >
      <Flex
        borderBottomWidth="2px"
        px="4"
        py="2"
        bg={bg}
        zIndex="1"
        alignItems="center"
        direction="row" gap="2" position="sticky" top="0">
        <Heading flex="1" size="md">{t('search')}</Heading>
        <CloseButton onClick={searchUi.toggleSidebar} />
      </Flex>
      <Flex maxHeight="calc(100vh - 64px)" gap={2} direction="column" overflow="auto" >
        {taxonQuery.isLoading || taxon.id === undefined ? (
          <LoadingSidebarIndicator />
        ) : (
          <TaxonomyFilterOptions taxon={taxon} />
        )}
      </Flex>
    </Box> : null
};

const TaxonomyItem = ({ taxonomy, taxon }) => {
  const [isOpen, setOpen] = React.useState(false)
  const similarTaxonsQuery = useFetchSimilarTaxons({ taxonomy_id: taxonomy.id, taxon_id: taxon.id }, { enabled: isOpen })
  const [count, setCount] = React.useState(parseInt(taxonomy.taxons))
  return <>
    <h2>
      <AccordionButton onClick={() => setOpen(true)} borderWidth="0" _expanded={{ color: "orange.500" }}>
        <Heading
          textAlign="left"
          size="md"
          as="div"
        >
          {taxonomy.title}
        </Heading>
        <AccordionIcon />
        {count > 0 && <Box ml="auto"><Badge colorScheme="orange">{count}</Badge></Box>}
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      {similarTaxonsQuery.data.taxons.map((tax) => (
        <Flex alignItems="center" key={tax.id} direction="row" gap="2">
          <Checkbox onChange={e => {
            if (e.target.checked)
              setCount(count + 1)
            else
              setCount(count - 1)
          }} flex="1" value={tax.id.toString()}>
            <Box noOfLines={1}>{tax.title}</Box>
          </Checkbox>
          <Tooltip label={tax.title}>
            <NavLink aria-label={taxon.title} to={tax.href}>
              <FontAwesomeIcon icon={faFolder} />
            </NavLink>
          </Tooltip>
        </Flex>
      ))}
    </AccordionPanel>
  </>
}

const TaxonomyFilterOptions = ({ taxon }) => {
  const [query, setQuery] = useProductSearchParams({ taxon_ids: [taxon.id.toString()] });
  const [first, ...taxonomies] = taxon.filterable_taxonomies;

  return (
    <CheckboxGroup
      value={query.taxon_ids}
      onChange={(value) => setQuery({ taxon_ids: value })}
    >
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton borderWidth="0" _expanded={{ color: "orange.500" }}>
              <Heading
                textAlign="left"
                size="md"
                as="div"
              >
                {first.title}
              </Heading>
              <AccordionIcon />
              <Box ml="auto">
                <Badge colorScheme="orange">1</Badge>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex alignItems="center" direction="row" gap="2">
              <Checkbox isDisabled flex="1" value={taxon.id.toString()}>
                <Box noOfLines={1}>{taxon.title}</Box>
              </Checkbox>
              <Tooltip label={taxon.title}>
                <NavLink aria-label={taxon.title} to={taxon.href}>
                  <FontAwesomeIcon icon={faFolder} />
                </NavLink>
              </Tooltip>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        {taxonomies.map((taxonomy) => <AccordionItem key={taxonomy.id}><TaxonomyItem taxon={taxon} taxonomy={taxonomy} key={taxonomy.id} /></AccordionItem>)}
      </Accordion>
    </CheckboxGroup>
  );
};
