import React from "react";
import {
  Grid,
  Heading,
  Flex,
  Box,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  Portal,
} from "@chakra-ui/react";
import { NavLink } from "../elements";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useFetchTaxonomy } from "../api/queries";
import { TaxonCard } from "../taxons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { useSearchUi } from "../hooks";
import { TaxonomyIcon } from "../taxonomies/TaxonomyIcon";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

export const TaxonomyMenu = ({ taxonomyId, setTaxonomyId, taxonomy, size, containerRef }) => {
  const ui = useSearchUi();
  const [topOnOpen, setTopOnOpen] = React.useState(0)
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  React.useEffect(() => {
    if (isOpen)
      setTopOnOpen(window.scrollY > 300 ? window.scrollY : 0)
    else
      setTopOnOpen(0)
  }, [isOpen])
  const location = useLocation();
  React.useEffect(() => onClose, [location.pathname, location.search]);
  const taxonomyQuery = useFetchTaxonomy(
    taxonomy.id,
    { view: "menu" },
    { enabled: isOpen }
  );
  const menuBg = useColorModeValue("white", "black");
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
  const { t } = useTranslation();
  return (
    <Flex overflow="clip" direction="column">
      <NavLink
        aria-label={`popular in ${taxonomy.title}`}
        rightIcon={
          <motion.div animate={{ transform: taxonomy.id === taxonomyId && isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
            <FontAwesomeIcon
              icon={faChevronDown}
            />
          </motion.div>
        }
        isActive={isOpen}
        onClick={() => {
          if (taxonomy.id === taxonomyId && isOpen) onClose()
          else {
            setTaxonomyId(taxonomy.id)
            onOpen()
            window.scrollTo({ top: 0, behavior: "smooth" })
          }
        }}
        size={size}
      >
        {taxonomy.title}
      </NavLink>
      <Portal containerRef={containerRef}>
        <AnimatePresence>
          {isOpen && taxonomyId == taxonomy.id && taxonomyQuery.isSuccess && (
            <>
              <Box zIndex="10" position="fixed" top="0" left="0" bottom="0" right="0" onClick={onClose} />
              <Box
                left={[2, 4]}
                right={[2, 4]}
                top={[2, 4]}
                position="absolute"
                borderWidth="2px"
                zIndex="202"
                as={motion.div}
                initial={{
                  opacity: 0,
                  transform: 'scaleY(0)'
                }}
                exit={{
                  opacity: 0,
                  transform: 'scaleY(0)',
                  originY: 'bottom'
                }}
                animate={{
                  opacity: 1,
                  transform: 'scaleY(1)',
                  originY: 'top'
                }}
                overflow="auto"
                bg={menuBg}
              >
                <Flex
                  direction="column"
                  gap={[2, 4]}
                >
                  <Flex
                    bg={menuBg}
                    zIndex="1"
                    position="sticky"
                    top="0"
                    borderBottomWidth="2px"
                    alignItems="center"
                    borderColor="orange.600"
                    direction="row"
                    gap="2"
                    py="2"
                    px={[2, 4]}
                  >
                    <TaxonomyIcon id={taxonomy.id} />
                    <Heading p={2} as={Link} to={taxonomy.href + "?order=popularity"} size="lg" noOfLines={1}>
                      {taxonomy.title}{" "}
                    </Heading>
                    <NavLink
                      mr="auto"
                      size="lg"
                      leftIcon={<FontAwesomeIcon icon={faSearch} />}
                      onClick={() => {
                        ui.pickTaxonomy(taxonomyQuery.data.id);
                        onClose();
                      }}
                    >
                      {t("search-taxonomy", {
                        title: taxonomyQuery.data.title,
                      })}
                    </NavLink>
                    <CloseButton onClick={onClose} />
                  </Flex>
                  <Grid
                    py="2"
                    px={[2, 4]}
                    gap="4"
                    templateColumns={[
                      "repeat(auto-fill,minmax(240px, 1fr))",
                    ]}
                  >
                    {taxonomyQuery.data.taxons.map((taxon) => (
                      <TaxonCard taxon={taxon} key={taxon.id} />
                    ))}
                    <Flex p="8" as={Link} alignItems="center" justifyContent="center" bg={bg} to={taxonomy.href}>
                      <Heading size="sm">{t('show-all')}</Heading>
                    </Flex>
                  </Grid>
                </Flex>
              </Box>
            </>
          )}
        </AnimatePresence>
      </Portal>
    </Flex >
  );
};
