import React from "react";
import {
  Flex,
  Tag,
  TagLabel,
  TagLeftIcon,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Outlet, Link, useParams } from "react-router-dom";
import { useFetchTaxon } from "../api";
import { TaxonomyIcon } from "../taxonomies/TaxonomyIcon";
import { useTranslation } from "react-i18next";

export const TaxonSuggestions = () => {
  const { taxonId } = useParams();
  const { t } = useTranslation();
  const taxonQuery = useFetchTaxon(
    taxonId,
    { view: "show" },
    { refetchOnMount: false }
  );
  return (
    <>
      {taxonQuery.data.taxon_suggestions.length > 0 && (
        <nav aria-labelledby={`${taxonId}-menu`}>
          <VisuallyHidden as="h2" id={`${taxonId}-menu`}>
            {t("submenu-for", {
              taxon: taxonQuery.data.title,
              taxonomy: taxonQuery.data.taxonomy.title,
            })}
          </VisuallyHidden>
          <Flex
            sx={{
              webkitOverflowScrolling: "touch",
              '::-webkit-scrollbar': { display: 'none' }
            }} whiteSpace="nowrap" overflowY="hidden" width="100%" as="ul" gap="2" pb="4" flexDirection="row" overflowX="auto">
            {taxonQuery.data.taxon_suggestions.map((taxon) => (
              <Tag
                flex="0 0 auto"
                as="li"
                key={taxon.id}
                size="lg"
                rounded="full"
                colorScheme="orange"
              >
                <TagLeftIcon>
                  <TaxonomyIcon id={taxon.taxonomy.id} />
                </TagLeftIcon>
                <TagLabel as={Link} to={taxon.href}>
                  {taxon.title}
                </TagLabel>
              </Tag>
            ))}
          </Flex>
        </nav>
      )}
      <Outlet />
    </>
  );
};
