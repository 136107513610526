import React from "react";
import {
  Heading,
  Grid,
  GridItem,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useFetchTaxonomy } from "../api/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DefinitionItem, LoadingSidebarIndicator } from "../elements";
import { TaxonIndexItem } from "../taxons/TaxonIndexItem"
import { useTranslation } from "react-i18next";
import { faSortAmountUp } from "@fortawesome/pro-regular-svg-icons/faSortAmountUp";
import { faSortAmountDown } from "@fortawesome/pro-regular-svg-icons/faSortAmountDown";
import { faArrowUpAZ, faRankingStar } from "@fortawesome/pro-regular-svg-icons";
import { useSearchParams } from "react-router-dom";

const icons = {
  "title": faArrowUpAZ,
  "popularity": faRankingStar
}
const NextPage = ({ page, query }) => {
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200")
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
  const { taxonomyId } = useParams();
  const [startFetching, setStartFetching] = React.useState(false)
  const taxonomyQuery = useFetchTaxonomy(taxonomyId, {
    ...query,
    page
  }, { enabled: startFetching });
  if (startFetching)
    return <>
      {taxonomyQuery.isSuccess &&
        taxonomyQuery.data.taxons.map((taxon, i) => <TaxonIndexItem icon={icons[query.order]} taxon={taxon} num={((page - 1) * 50) + i + 1} key={taxon.id} />)
      }
      {taxonomyQuery.isSuccess && taxonomyQuery.data.total_pages > parseInt(page) && <NextPage query={query} page={page + 1} />}
    </>
  else return <GridItem px={[2, 4]} py={[8, 16]} fontWeight="semibold" color="gray.500" as={Flex} colSpan={[1, 1, 2]} bg={bg} _hover={{ bg: hoverBg }} cursor="pointer" onClick={() => setStartFetching(true)} mt={4} alignItems="center" justifyContent="center">
    Seite {page} laden
  </GridItem>
}

export const Taxonomy = () => {
  const { taxonomyId } = useParams();
  const { t } = useTranslation()
  const bg = useColorModeValue("white", "gray.800");
  const [search, setSearch] = useSearchParams();
  const [query, setQuery] = React.useState(
    {
      view: "show",
      order: search.get("order") || "title",
      direction: search.get("direction") || 'asc',
    }
  )
  const taxonomyQuery = useFetchTaxonomy(taxonomyId, query);
  if (taxonomyQuery.isLoading) return <LoadingSidebarIndicator />;

  return (
    <Flex mb={16} direction="column">
      <Flex
        p="1"
        zIndex="10"
        position="sticky"
        top="46px"
        bg={bg}
        wrap="wrap"
        alignItems="center"
        gap={4}
        borderBottomWidth="2px"
      >
        <Box
          as="button"
          aria-label="toggle direction"
          onClick={() =>
            setQuery({
              ...query,
              direction: query.direction === "desc" ? "asc" : "desc",
            })
          }
        >
          <FontAwesomeIcon
            icon={
              query.direction === "desc" ? faSortAmountDown : faSortAmountUp
            }
          />
        </Box>
        <DefinitionItem label={t("sorted-by")}>
          <Menu isLazy>
            <MenuButton>{t(query.order)}</MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => setQuery({ ...query, order: "popularity", direction: "desc" })}
              >
                {t("popularity")}
              </MenuItem>
              <MenuItem
                onClick={() => setQuery({ ...query, order: "title", direction: "asc" })}
              >
                {t("title")}
              </MenuItem>
            </MenuList>
          </Menu>
        </DefinitionItem>
        <Heading as="h1" ml="auto" size="md">
          {taxonomyQuery.data.title}
        </Heading>
      </Flex>
      <Grid gap="4" templateColumns="repeat(auto-fill,minmax(350px, 1fr))">
        {taxonomyQuery.data.taxons.map((taxon, i) => <TaxonIndexItem icon={icons[query.order]} taxon={taxon} num={i + 1} key={taxon.id} />)}
        {taxonomyQuery.data.total_pages > 1 && <NextPage query={query} page={2} />}
      </Grid>
    </Flex>
  );
};
